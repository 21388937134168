import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { format, parse } from 'date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import enLocale from "date-fns/locale/en-US"
import deLocale from "date-fns/locale/de"
import { FormHelperText } from '@material-ui/core'
import { DataFieldFormControl } from './styled'
import { getLocaleDateFormat, backendDateFormat } from '../dateFormat'


/**
 * Renders data field of type _Datum_.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Date
 */
function DataFieldDate(props) {
  const {i18n} = useTranslation()

  /**
   * Field data extracted from _prop_ [data]{@link DataFieldDate}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldDate
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {bool} isMandatory
   * a boolean flag that shows if the data field is mandatory
   * @prop {string} [errorMessage]
   * an error message related to the field that comes from the back-end
   */
  const {data, value } = props


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the field holds an invalid value.
   *
   * @name error
   * @default false
   * @prop {bool} error - state
   * @prop {function} setError - setter
   * @type {state}
   * @memberOf DataFieldDate
   * @inner
   */
  const [error, setError] = React.useState()


  /**
   * Sets _false_ (_true_) to sate [error]{@link DataFieldNumber~error} if
   * prop [data.errorMessage]{@link DataFieldNumber.data} is empty (not empty).
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldDate
   * @inner
   * @arg {string} data.errorMessage
   * prop [data.errorMessage]{@link DataFieldNumber.data}
   */
  React.useEffect(() => {
    setError(Boolean(data.errorMessage))
  }, [data.errorMessage])

  /**
   * Method<br/>
   * Returns _en-US_ locale if current language is English.
   * Otherwise returns _de_ locale].
   * @see [date-fns]{@link https://date-fns.org/v2.22.1/docs/Locale} for locale details
   *
   * @function
   * @returns {object}
   */
  const getLocale = () => {
    switch (i18n.language) {
      case 'en':
        return enLocale
      default:
        return deLocale
    }
  }

  /**
   * Method<br/>
   * Converts prop [value]{@link DataFieldDate} to an instance of _Date_
   * using [date-fns]{@link https://date-fns.org/v1.28.5/docs/parse} library.
   *
   * @function
   * @returns {Date} 
   */
  const parseDateValue = () => {
    if (!value) {
      return null
    }

    return parse(value, backendDateFormat, new Date())
  }


  /**
   * Event Handler<br/>
   * **_Event:_** change input value.<br/>
   * **_Implementation:_**
   * pushes stringified current value (or empty string if current value is not a valid date)
   * to prop [onChange]{@link DataFieldDate} to update value of the field
   */
  const handleChange = (date) => {
    if (isNaN(date) || date === null || date === '') {
      props.onChange({[data.name]: ''})
      return
    }

    const strValue = format(date, backendDateFormat)
    props.onChange({[data.name]: strValue})
  }

  return (
    <DataFieldFormControl
      fullWidth
      error={error}
    >
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={getLocale()}
      >
        <KeyboardDatePicker
          id={`${data.name}`}
          autoOk
          size="small"
          inputVariant="outlined"
          label={data.brief}
          format={getLocaleDateFormat()}
          value={parseDateValue()}
          onChange={handleChange}
          required={data.isMandatory}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText>
        {data.errorMessage}
      </FormHelperText>
    </DataFieldFormControl>
  )
}

DataFieldDate.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldDate.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the date field in back-end format (default format: YYYY-MM-DD)
   */
  value: PropTypes.string,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
}

export default DataFieldDate