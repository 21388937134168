import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { validateJSONString } from '../../utils'

// styles
const useStyles = makeStyles((theme) => ({
  formItem: {
    marginBottom: theme.spacing(2),
  },
}))


/**
 * This component renders a dialog as a modal window
 * to edit a child company properties in the back-end.
 *
 * @component
 * @category Views
 * @subcategory Admin View
 */
function CompanyDialog(props) {
  const classes = useStyles()
  const {t} = useTranslation("common", "admin")
  
  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * Child company's attributes as a JSON string.
   *
   * @name attributes
   * @default ''
   * @prop {string} attributes - state
   * @prop {function} setAttributes - setter
   * @type {state}
   * @memberOf CompanyDialog
   * @inner
   */
  const [attributes, setAttributes] = React.useState('')
  /**
   * State<br/>
   * An error message related to child company's attributes (empty string if it is no error).
   *
   * @name errorMsg
   * @default undefined
   * @prop {string} errorMsg - state
   * @prop {function} setErrorMasg - setter
   * @type {state}
   * @memberOf CompanyDialog
   * @inner
   */
  const [errorMsg, setErrorMasg] = React.useState()


  /**
   * Sets prop [company.attributes]{@link CompanyDialog} to
   * state [attributes]{@link CompanyDialog~attributes} and
   * defines state [errorMsg]{@link CompanyDialog~errorMsg}
   * based on the [validation result]{@link module:Utils.validateJSONString}
   * of the attribute string when the component is mounted.
   *
   * @name useEffect
   * @function
   * @memberOf CompanyDialog
   * @inner
   */
  React.useEffect(() => {
    setAttributes(props.company.attributes)
    setErrorMasg(validateJSONString(props.company.attributes) ? null : t("admin:attributes.error"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * Event Handler<br/>
   * **_Event:_** change input value of _attributes_ field.<br/>
   * **_Implementation:_**<br/>
   * Sets the current input value and corresponding error message
   * to states [attributes]{@link CompanyDialog~attributes} and
   * [errorMsg]{@link CompanyDialog~errorMsg}.
   * The error message is derived from [validation result]{@link module:Utils.validateJSONString})
   * of the current value: _null_ if validation is successful.
   */
  const handleAttributeChange = (event) => {
    const newValue = event.target.value

    // update state
    setAttributes(newValue)
    setErrorMasg(validateJSONString(newValue) ? null : t("admin:attributes.error"))
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Confirm_' button.<br/>
   * **_Implementation:_**<br/>
   * pushes the current value of state [attributes]{@link CompanyDialog~attributes}
   * to prop [action]{@link CompanyDialog} to _edit_ the attribute of the child company on the back-end.
   */
  const handleConfirmClick = () => {
    // build request body
    const payload = {
      childCompanyId: props.company.id,
      attributes: attributes,
    }

    // reset fields
    setAttributes('')
    setErrorMasg('')

    props.action('edit', payload)
  }

  /**
   * Method<br/>
   * Validates if state [attributes]{@link CompanyDialog~attributes} keeps not empty string
   * along with empty value of state [errorMsg]{@link CompanyDialog~errorMsg}.
   *
   * Triggers disability of '_Confirm_' button.
   */
  const validateForm = () => {
    return Boolean(attributes) && !Boolean(errorMsg)
  }

  //console.log('COMPANY DIALOG:')
  //console.log(props)

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="company-dialog"
    >
      <DialogTitle
        id="company-dialog"
      >
        {t("admin:company.edit")}
      </DialogTitle>
      <DialogContent>

        {/* Company Name */}
        <Typography
          className={classes.formItem}
          id="company-name"
          variant="button"
          component="div"
        >
          {props.company.name}
        </Typography>

        {/* Company Attributes */}
        <TextField
          id="company-attributes"
          label={t("admin:attributes")}
          multiline
          fullWidth
          variant="outlined"
          value={attributes}
          onChange={handleAttributeChange}
          size="small"
          error={Boolean(errorMsg)}
          helperText={Boolean(errorMsg) ? t("admin:attributes.error"): ""}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          color="primary"
          onClick={handleConfirmClick}
          disabled={!validateForm()}
        >
          {t("common:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

CompanyDialog.propTypes = {
  /**
   * If _true_ then the dialog is opened
   */
  open: PropTypes.bool.isRequired,
  /**
   * An [instance of child company]{@link AdminView~childCompany}
   */
  company: PropTypes.object,
  /**
   * Callback fired to edit the attributes of a child company in the back-end
   */
  action: PropTypes.func,
  /**
   * Callback fired when the dialog should be closed
   */
  onClose: PropTypes.func,
}

export default CompanyDialog
