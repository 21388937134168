/**
 * Gamification Back-End API Calls
 *
 * @category API Calls
 * @module Gamification
 */


/**
 * GET `/api/badges/types`<br/>
 * Retrieve types of the badges which are available for the current company
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @returns {BadgeType[]}
 */
export const getBadgeTypes = async (user) => {
  const response = await fetch("/api/badges/types", {
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const data = await response.json()

  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * GET `/api/badges`<br/>
 * Retrieve the current user's badges
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @returns {Badge[]}
 */
export const getBadges = async (user) => {
  const response = await fetch("/api/badges", {
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * GET `/api/badge/{route}`<br/>
 * Retrieve badge image by its route and returns an URL to its object
 * within the front-end app
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {string} route
 * a local route to the badge on the back-end side
 * @returns {string}
 */
export const getBadgeSrc = async (user, route) => {
  const response = await fetch(`/api/badge/${route}`, {
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const blob = await response.blob()
  const src = await (window.URL ? window.URL : window.webkitURL).createObjectURL(blob)

  if (response.ok) {
    return src
  }

  throw new Error('Failed fetch badge image')
}


/**
 * POST `/api/badges/seen`<br/>
 * Marks the given badge as _seen_ on the back-end
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {Badge} payload
 * the target badge instance 
 * @returns {object}
 */
export const makeBadgeSeen = async (user, payload) => {
  const response = await fetch("/api/badges/seen", {
  	method: 'POST',
    headers: {
      'authorization': `Bearer ${user.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * GET `/api/rankings`<br/>
 * Retrieve rankings data for the current user
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @returns {object}
 */
export const getRankings = async (user) => {
  const response = await fetch("/api/rankings", {
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}