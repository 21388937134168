import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Typography, Collapse } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { CardError, CardTop, CardMiddle, hideTime } from '../styles/cards'
import CardTitle from '../components/cardTitle'
import { removePolicy } from '../redux/actions'
import CardCloseButton from '../components/closeButton'


/**
 * This component renders a policy card with the request status _failed_.
 * @see [MapPolicyCard]{@link PolicyView.MapPolicyCard} for possible request status of a policy instance
 *
 * @component
 * @category Policy
 */
function ErrorPolicy(props) {
  const {index, policy} = props
  const { t } = useTranslation('common', 'policy')

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State: Boolean flag that defines the card visibility.
   * Used to animate the card appearance and closure. 
   *
   * @name isVisible
   * @default false
   * @prop {boolean} isVisible - state
   * @prop {function} setIsVisible - setter
   * @type {state}
   * @memberOf ErrorPolicy
   * @inner
   */
  const [isVisible, setIsVisible] = useState(false)

  /**
   * Implements animation of the card appearance
   * by setting state [isVisible]{@link ErrorAntrag~isVisible} to _true_.
   *
   * @name useEffect
   * @function
   * @memberOf ErrorAntrag
   * @inner
   */
  React.useEffect(() => {
    setIsVisible(true)
  }, [])

  //console.log('ERROR POLICY:')
  //console.log(policy)

  return(
    <Collapse
      in={isVisible}
      timeout={hideTime}
      unmountOnExit
    >
      <CardError>
        <CardTop
          action={
            <CardCloseButton
              onClose={() => setIsVisible(false)}
              onDelete={() => props.closePolicy(index)}
            />
          }
          title={
            <CardTitle
              type="policy"
              name={policy.policy_number}
            />
          }
          subheader={policy.effective_date}
        />
        <CardMiddle>
          <Typography
            component="p"
            variant="h5"
          >
            {"error" in policy ? (policy.error) : (t("policy:invalid"))}
          </Typography>
        </CardMiddle>
      </CardError>
    </Collapse>
  )
}

ErrorPolicy.propTypes = {
  /**
   * The index of the policy instance in the [redux store]{@link module:State~policies}
   */
  index: PropTypes.number,
  /**
   * The policy instance
   */
  policy: PropTypes.object,
  /**
   * Redux action [removePolicy]{@link module:Actions.removePolicy}
   */
  closePolicy: PropTypes.func,
}

// connect to redux store
export default connect(null, {closePolicy: removePolicy})(ErrorPolicy)
