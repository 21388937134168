import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  Tooltip,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/core/styles'
import { apiHost } from '../utils'

const uriImages = apiHost + 'api/images/'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
  },

  imageContainer: {
    position: "relative",
    display: "inline-block",
    marginRight: theme.spacing(5),
  },

  png: {
    display: "block",
    width: props => props.width,
    height: props => props.height,
  },

  svg: {
    position: "absolute",
    top: 0,
    left: 0,
  },

  bodyPart: {
    fill: theme.palette.primary.main,
    fillOpacity: 0,
    "&:hover": {
      fillOpacity: 0.5,
    },
  },
}))


/**
 * This component renders an output data field of type _Image_.
 *
 * @component
 * @category Data Fields
 * @subcategory Output
 */
function MappedImage(props) {
  
  /**
   * Image data extracted from prop [data]{@link MappedImage}
   *
   * @name data
   * @type {object}
   * @memberOf MappedImage
   * @prop viewBox {string}
   * defines positions and dimensions of the image viewport.
   * The value is a string of four numbers: _min-x_, _min-y_, _width_, _height_
   * -- separated by spaces
   * @prop height {number} - the height of the rendered image 
   * @prop width {number} - the width of the rendered image
   * @prop areas {object.<string, object>}
   * definitions of the interactive areas
   * @prop areas.<string>.title {string} - the title of an area
   * @prop areas.<string>.value {string[]}
   * a list of strings which are paragraphs of the description of an area
   * @prop areas.<string>.path {string}
   * the shape of an area in form of
   * [SVG path]{@link https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths} 
   */
  const { data } = props

  // styles
  const classes = useStyles(data)

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * The currently active area from ones defined in [data.areas]{@link MappedImage.data}
   *
   * @name currentArea
   * @default undefined
   * @prop {object} currentArea - state
   * @prop {function} setCurrentArea - setter
   * @type {state}
   * @memberOf MappedImage
   * @inner
   */
  const [currentArea, setCurrentArea] = React.useState()

  /**
   * State<br/>
   * A boolean flag that signals if tooltip of the field should be shown
   *
   * @name infoOpen
   * @default false
   * @prop {bool} infoOpen - state
   * @prop {function} setInfoOpen - setter
   * @type {state}
   * @memberOf MappedImage
   * @inner
   */
  const [infoOpen, setInfoOpen] = React.useState(false)


  /**
   * Event Handler<br/>
   * **_Event:_** move mouse over an area of the image<br/>
   * **_Implementation:_**
   * if mouse is over an area defined in prop [data.areas]{@link MappedImage.data},
   * then set the object of this area to state [currentArea]{@link MappedImage~currentArea}
   */
  const handleMouseMove = (event) => {
    const target = event.target.id

    if (target) {
      setCurrentArea(data.areas[target])
    }
  }

  /**
   * Event Handler<br/>
   * **_Event:_ mouse leaves the current area of the image<br/>
   * **_Implementation:_**
   * set _undefined_ to state [currentArea]{@link MappedImage~currentArea}
   */
  const handleMouseLeave = () => {
    setCurrentArea()
  }

  /**
   * Event Handler<br/>
   * **_Event:_ click info button<br/>
   * **_Implementation:_**
   * set _true_ to state [infoOpen]{@link MappedImage~infoOpen}
   */
  const handleInfoOpen = () => {
    setInfoOpen(true)
  }

  /**
   * Event Handler<br/>
   * **_Event:_**: click outside an info tooltip
   * **_Implementation:_**
   * set _false_ to state [infoOpen]{@link MappedImage~infoOpen}
   */
  const handleInfoClose = () => {
    setInfoOpen(false)
  }


  return(
    <Grid
      className={classes.container}
      container
      direction="column"
      spacing={2}
    >
      <Grid
        item
        container
        spacing={1}
      >
        <Grid item>
          <Typography
            variant="h6"
            component="div"
          >
            {props.title}
          </Typography>
        </Grid>
        <Grid item>
          <ClickAwayListener onClickAway={handleInfoClose}>
            <div>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleInfoClose}
                open={infoOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={props.tooltip}
                placement="top"
              >
                <IconButton
                  aria-label="info"
                  color="primary"
                  size="small"
                  onClick={handleInfoOpen}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </Grid>
      </Grid>
      <Grid
        item
        container
      >
        <Grid
          className={classes.imageContainer}
          item
        >
          <img
            className={classes.png}
            src={uriImages + props.image}
            alt=""
          />
          <svg
            className={classes.svg}
            viewBox={data.viewBox}
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              {Object.keys(data.areas).map(key => (
                <path
                  key={key}
                  id={key}
                  d={data.areas[key].path}
                  className={classes.bodyPart}
                />
              ))}
            </g>
          </svg>
        </Grid>
        <Grid item> 
          {Boolean(currentArea) &&
            <React.Fragment>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  component="div"
                >
                  {currentArea.title}
                </Typography>
              </Grid>
              {currentArea.value.map((line, index) => (
                <Grid key={index} item>
                  <Typography
                    variant="body1"
                    component="div"
                  >
                    {line}
                  </Typography>
                </Grid>
              ))}
            </React.Fragment>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

MappedImage.propTypes = {
  /**
   * the title of the image
   */
  title: PropTypes.string,
  /**
   * image data (see [data]{@link MappedImage.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * image tooltip
   */
  tooltip: PropTypes.string,
  /**
   * filename that should be used to access the image on the back-end
   */
  image: PropTypes.string.isRequired,
}

export default MappedImage
