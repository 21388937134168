import React from 'react'
import { Typography } from '@material-ui/core'

/**
 * This component defines and renders the copyright string. 
 *
 * @component
 * @category Auxiliary Components
 */
function Copyright() {

  /**
   * Current year
   */
  const year = new Date().getFullYear()

  return(
    <Typography variant="body2" color="textSecondary" align="center">
      {`PoLZy - the SIMPLE Insurance Policy Life Cycle Management © ${year}`}
    </Typography>
  )
}

export default Copyright
