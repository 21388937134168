import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import UserDialog from './userDialog'
import CompanyDialog from './companyDialog'
import CompanyCard from './companyCard'
import { fetchAdminData, manageUserInCompany, manageChildCompany } from '../../api/admin'

/**
 * It is a view component that defines the layout for the admin panel.
 *
 * @component
 * @category Views
 */
function AdminView(props) {

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * The admin data which is available for the current user.
   *
   * @name adminData
   * @default undefined
   * @prop {object} adminData - state
   * @prop {object[]} adminData.companies
   * a list of company object which the current user is granted to administrate
   * @prop {string[]} adminData.possibleRoles
   * a list of the possible user's roles in the system
   * @prop {function} setAdminData - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [adminData, setAdminData] = React.useState()

  /**
   * State<br/>
   * A boolean flag that signals if [user dialog]{@link UserDialog} should be opened.
   *
   * @name userOpen
   * @default false
   * @prop {bool} userOpen - state
   * @prop {function} setUserOpen - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [userOpen, setUserOpen] = React.useState(false)

  /**
   * State<br/>
   * A boolean flag that signals if [company dialog]{@link CompanyDialog} should be opened.
   *
   * @name companyOpen
   * @default false
   * @prop {bool} companyOpen - state
   * @prop {function} setCompanyOpen - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [companyOpen, setCompanyOpen] = React.useState(false)

  /**
   * State<br/>
   * ID of the currently opened company.
   *
   * @name parentCompanyId
   * @default undefined
   * @prop {string} parentCompanyId - state
   * @prop {function} setCompanyOpen - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [parentCompanyId, setParentCompanyId] = React.useState()

  /**
   * State<br/>
   * An instance of the selected child company.
   *
   * @name childCompany
   * @default undefined
   * @prop {object} childCompany - state
   * @prop {string} childCompany.id
   * ID of the company
   * @prop {string} childCompany.name
   * company name
   * @prop {string} childCompany.attributes
   * company attributes as a JSON string
   * @prop {function} setChildCompany - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [childCompany, setChildCompany] = React.useState()

  /**
   * State<br/>
   * An instance of the selected user in a company.
   *
   * @name userInCompany
   * @default undefined
   * @prop {object} userInCompany - state
   * @prop {string} userInCompany.id
   * ID of the user
   * @prop {string} userInCompany.email
   * user's email
   * @prop {string} userInCompany.attributes
   * user's attributes in the company as a JSON string
   * @prop {function} setUserInCompany - setter
   * @type {state}
   * @memberOf AdminView
   * @inner
   */
  const [userInCompany, setUserInCompany] = React.useState()
  

  /**
   * Calls the back-end [fetchAdminData]{@link module:Admin.fetchAdminData}
   * to retrieve the administration data. If the response is successful,
   * then sets it to state [adminData]{@link AdminView~adminData}.
   *
   * @name useEffect
   * @function
   * @memberOf AdminData
   * @inner
   */
  React.useEffect(() => {
    fetchAdminData(props.user).then(data => {

      //update state
      setAdminData(data)

    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * Event Handler<br/>
   * **_Event:_** click '_edit_' action for a user or a child company.<br/>
   * **_Implementation:_**<br/>
   * If `target === 'user'` then sets:
   * * the instance of the selected user to state [userInCompany]{@link AdminView~userInCompany}
   * * _true_ to state [userOpen]{@link AdminView~userOpen}
   * If `target === 'company'` then sets:
   * * the instance of the selected child company to state [childCompany]{@link AdminView~childCompany}
   * * _true_ to state [companyOpen]{@link AdminView~companyOpen}
   *
   * @arg {string} target
   * identification of the target instance. Possible values: _user_, _company_
   * @arg {string} parentCompanyId
   * ID of the currently opened company
   * @arg {object} [instance]
   * target instance of a user or a company 
   */
  const handleOpenDialog = (target, parentCompanyId, instance=undefined) => {

    setParentCompanyId(parentCompanyId)

    switch (target) {
      case 'user':
        setUserInCompany(instance)
        setUserOpen(true)
        break
      case 'company':
        setChildCompany(instance)
        setCompanyOpen(true)
        break
      default:
        return
    }
  }

  /**
   * Callback<br/>
   * Fired to update a user in the current company on the back-end.<br/>
   * **_Implementation:_**
   * calls the back-end [manageUserInCompany]{@link module:Admin.manageUserInCompany}.
   * If the response is successful then sets it to state [adminData]{@link AdminView~adminData}.
   *
   * @arg {string} action
   * identification of the action to be done on a user. Possible values: _edit_, _add_, _remove_
   * @arg {object} payload
   * action payload
   * @arg {string} payload.email
   * user's email
   * @arg {object} [payload.roles]
   * user's new roles in the current company as pairs _role name_-_true_/_false_
   * @arg {string} [payload.attributes]
   * user's new attributes in the current company as a JSON string
   */
  const handleManageUser = (action, payload) => {
    // build request body
    const requestData = {
      companyId: parentCompanyId,
      ...payload,
    }

    // run action
    manageUserInCompany(props.user, action, requestData).then(data => {
      // update state
      setAdminData(data)
    }).catch(error => {
      console.log(error)
    })

    setUserOpen(false)
  }

  /**
   * Callback<br/>
   * Fired to update a child company of the current company in the back-end.<br/>
   * **_Implementation:_**
   * calls the back-end [manageChildCompany]{@link module:Admin.manageChildCompany}.
   * If the response is successful then sets it to state [adminData]{@link AdminView~adminData}.
   *
   * @arg {string} action
   * identification of the action to be done on a child company. Possible value: _edit_, _remove_
   * @arg {object} payload
   * action payload
   * @arg {string} payload.childCompanyId
   * ID of the child company
   * @arg {string} [payload.attributes]
   * new attributes for the child company as a JSON string
   */
  const handleManageCompany = (action, payload) => {
    // build request body
    const requestData = {
      parentCompanyId: parentCompanyId,
      ...payload,
    }

    // run action
    manageChildCompany(props.user, action, requestData).then(data => {
      // update state
      setAdminData(data)
    }).catch(error => {
      console.log(error)
    })

    // close dialog
    setCompanyOpen(false)
  }
  
  //console.log('Admin Data:')
  //console.log(adminData)
  //console.log('User in Comapny:')
  //console.log(userInCompany)

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {Boolean(adminData) && adminData.companies.map(company => (
          <Grid item xs={12} key={company.id}>
            <CompanyCard
              {...company}
              actions={{
                user: handleManageUser,
                company: handleManageCompany,
              }}
              openDialog={handleOpenDialog}
            />
          </Grid>
        ))}
      </Grid>
      {Boolean(adminData) &&
        <React.Fragment>
          <UserDialog
            user={userInCompany}
            possibleRoles={adminData.possibleRoles}
            open={userOpen}
            action={handleManageUser}
            onClose={() => setUserOpen(false)}
          />
          {Boolean(childCompany) &&
            <CompanyDialog
              company={childCompany}
              open={companyOpen}
              action={handleManageCompany}
              onClose={() => setCompanyOpen(false)}
            />
          }
        </React.Fragment>
      }
    </React.Fragment>
  )
}

AdminView.propTypes = {
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(AdminView)
