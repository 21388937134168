import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Tab,
  Tabs,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ProgressButton from '../components/progressButton'
import { getRankings } from '../api/gamification'
import { formatNumberWithCommas } from '../utils'
import { formatRankWithSuffix } from '../utils'


// styles
const useStyles = makeStyles((theme) => ({
  updateButtonContainer: {
    margin: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))


/**
 * **PoLZy** gamification feature allows to tracks user's raiting within a company.
 * This component displays the user rating in several time scopes (_weekly_, _monthly_,
 * _annualy_ for instance). 
 *
 * @component
 * @category Views
 */
function RankingView(props) {
  const classes = useStyles()
  const {t} = useTranslation('gamification')

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * Name of the currently selected tab.
   * Each tab corresponds to a specific time frame.
   *
   * @name currentTab
   * @default undefined
   * @prop {string} currentTab - state
   * @prop {function} setCurrentTab - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [currentTab, setCurrentTab] = React.useState()

  /**
   * State<br/>
   * List of all view tabs.
   * Each tab corresponds to a specific time frame.
   *
   * @name allTabs
   * @default []
   * @prop {string[]} allTabs - state
   * @prop {function} setAllTabs - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [allTabs, setAllTabs] = React.useState([])

  /**
   * State<br/>
   * Ranking data which is a number of lists of users' rank data.
   * Each list is assigned to a corresponded time frame.
   *
   * @name rankingData
   * @default undefined
   * @prop {object.<string, object[]>} rankingData - state
   * @prop {string} rankingData.name
   * user name
   * @prop {number} rankingData.operations
   * number of user's operations
   * @prop {number} rankingData.rank
   * user's rank
   * @prop {function} setRankingData - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [rankingData, setRankingData] = React.useState()

  /**
   * State<br/>
   * A boolean flag, which shows if ranking data is been retrieving form the back-end now. 
   *
   * @name loading
   * @default true
   * @prop {bool} loading - state
   * @prop {function} setLoading - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [loading, setLoading] = React.useState(true)

  /**
   * State<br/>
   * The number of ranking records to be rendered.
   *
   * @name rankingLines
   * @default 3
   * @prop {number} rankingLines - state
   * @prop {function} setRankingLines - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [rankingLines, setRankingLines] = React.useState(3)

  /**
   * State<br/>
   * A boolean flag, which shows if an error occurred while loading ranking data form the back-end. 
   *
   * @name errorData
   * @default false
   * @prop {bool} errorData - state
   * @prop {function} setErrorData - setter
   * @type {state}
   * @memberOf RankingView
   * @inner
   */
  const [errorData, setErrorData] = React.useState(false)

  /**
   * Inner Component<br/>
   * Renders placeholder preview of the ranking data while it gets loaded.
   */
  const WaitingPlaceholder = () => {
    const waitingRows = []
    const waitingCells = []

    for (let cell = 0; cell < 3; cell++) {
      waitingCells.push(
        <TableCell key={cell}>
          <Skeleton />
        </TableCell>
      )
    }
    
    for (let row = 0; row < rankingLines; row++) {
      waitingRows.push(
        <TableRow key={row}>
          {waitingCells}
        </TableRow>
      )
    }

    return waitingRows
  }

  /**
   * Inner Component<br/>
   * Renders a ranking table cell. If the current data is addressed to
   * the current user, then shows it highlighted.
   */
  const EnhancedCell = (subProps) => {
    if (subProps.name === props.user.email) {
      return (
        <TableCell align={subProps.align}>
          <strong>{subProps.children}</strong>
        </TableCell>
      )
    }

    return (
      <TableCell align={subProps.align}>
        {subProps.children}
      </TableCell>
    )
  }


  /**
   * Method<br/>
   * Calls the back-end [getRankings]{@link module:Gamification.getRankings}
   * for ranking data. If the response is successful then updates the states as follow:
   * * sets the retrieved data to [rankingData]{@link RankingView~rankingData}
   * * sets the list of the retrieved object's keys to [allTabs]{@link RankingView~allTabs}
   * * sets the first key of the retrieved object to [currentTab]{@link RankingView~currentTab}
   * * sets the length of the first data list from the retrieved data to
   * [rankingLines]{@link RankingView~rankingLines}
   * 
   * @returns null
   */
  const updateRankings = () => {
    getRankings(props.user).then(data => {
      setRankingData(data)

      // get 
      const dataKeys = Object.keys(data)
      
      if (dataKeys.length > 0) {
        setAllTabs(dataKeys)
        setCurrentTab(dataKeys[0])
        setRankingLines(data[dataKeys[0]].length)
      }
    }).catch(error => {
      setErrorData(true)
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }
  

  /**
   * Fetches the ranking data by calling method [updateRankings]{@link RankingView~updateRankings}
   * when the component is mounted.
   *
   * @name useEffect
   * @function
   * @memberOf RankingView
   * @inner
   */
  React.useEffect(() => {
    updateRankings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * Event Handler<br/>
   * **_Event:_** click a '_Update Ranking_' button.<br/>
   * **_Implementation:_**<br/>
   * Calls method [updateRankings]{@link RankingView~updateRankings}.
   */
  const handleUpdateRankings = () => {
    setLoading(true)
    updateRankings()
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click a time frame tab.<br/>
   * **_Implementation:_**<br/>
   * Sets the value of the clicked tab to state [currentTab]{@link RankingView~currentTab}.
   */
  const handleTabChange = (event, value) => {
    setCurrentTab(value)
  }

  //console.log('Ranking')
  //console.log(props)
  //console.log(rankingData)
  //console.log(currentTab)

  return(
    <React.Fragment>
      {errorData ? (
         <Typography
            className={classes.formItem}
            variant="h3"
            component="div"
            align="center"
          >
            {t('common:na')}
          </Typography>
      ) : (
        <React.Fragment>

          {/* Ranking Tabs */}
          <Tabs 
            value={currentTab ? currentTab : allTabs[0]}
            onChange={handleTabChange}
            indicatorColor="secondary"
            textColor="primary"
            variant="fullWidth"
          >
            {allTabs.map((tab) => (
              <Tab
                key={tab}
                label={t(`gamification:${tab}`)}
                value={tab}
                id={`tab-${tab}`}
              />
            ))}
          </Tabs>

          {/* Ranking Table */}
          <Table>
            <colgroup>
              <col style={{width:'40%'}}/>
              <col style={{width:'30%'}}/>
              <col style={{width:'35%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell align='left'>
                  {t("gamification:topic")}
                </TableCell>
                <TableCell align="center">
                  {t("gamification:points")}
                </TableCell>
                <TableCell align="center">
                  {t("gamification:rank")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading || !Boolean(currentTab) ? (
                WaitingPlaceholder()
              ) : (
                <React.Fragment>
                  {rankingData[currentTab].map((data, index) => (
                    <TableRow key={index}>
                      <EnhancedCell align='left' name={data.name}>
                        {data.name}
                      </EnhancedCell>
                      <EnhancedCell align='center' name={data.name}>
                        {formatNumberWithCommas(data.operations)}
                      </EnhancedCell>
                      <EnhancedCell align='center' name={data.name}>
                        {data.rank}<sup>{formatRankWithSuffix(data.rank)}</sup>
                      </EnhancedCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              )}
            </TableBody>
          </Table>

          {/* Update Button */}
          <div className={classes.updateButtonContainer} >
            <ProgressButton
              title={t('gamification:update')}
              loading={loading}
              onClick={handleUpdateRankings}
            />
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RankingView.propTypes ={
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(RankingView)
