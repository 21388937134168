import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  FormHelperText,
  Input,
} from '@material-ui/core'
import { DataFieldSlider, SliderTitle } from './styled'
import { typingTimeoutWithInputTrigger, formatNumberWithSuffix } from '../utils'



// Styles
const useStyles = makeStyles((theme) => ({
  slider: {
    root: {
      padding: 0,
      color: props => props.error ? theme.palette.error.main : theme.palette.primary.main,
    },
    rail: {
      height: 5,
    },
  },

  sliderTitle: {
    marginTop: -theme.spacing(1),
  },

  sliderInput: {
    width: 90,
  },

}))


/**
 * Renders data field of type _Zahl_ with predefined range of values as a slider
 * with a numeric input field.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Numeric
 */
function DataFieldNumberSlider(props) {
  const {t} = useTranslation('common')

  /**
   * Field data extracted from _prop_ [data]{@link DataFieldNumberSlider}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldNumberSlider
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {array} inputRange
   * array of 3-4 elements, which define the range of possible values:
   * @prop {string} inputRange.0
   * should be "_slider_" -- 
   * signals, that the current numeric should be rendered as a slider
   * @prop {string} inputRange.1
   * the lowest value of the range
   * @prop {string} inputRange.2
   * the highest value of the range
   * @prop {string} [inputRange.3]
   * the step of value change
   * @prop {bool} [inputTriggers]
   * a boolean flag that shows if the parent instance should be updated
   * on the back-end if the current value is changed
   * @prop {string} [errorMessage]
   * an error message related to the field that comes from the back-end
   */
  const {id, data } = props

  // range boundaries
  /**
   * The lowest boundary of the value range.
   * Derived from _inputRange[1]_ of prop [data]{@link DataFieldNumberSlider.data}
   */
  const min = Number(data.inputRange[1])
  /**
   * The highest boundary of the value range.
   * Derived from _inputRange[2]_ of prop [data]{@link DataFieldNumberSlider.data}
   */
  const max = Number(data.inputRange[2])
  /**
   * The step of value changing.
   * Derived from _inputRange[3]_ of prop [data]{@link DataFieldNumberSlider.data}
   */
  const step = data.inputRange[3] ? Number(data.inputRange[3]) : undefined


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the field holds an invalid value.
   *
   * @name error
   * @default false
   * @prop {bool} error - state
   * @prop {function} setError - setter
   * @type {state}
   * @memberOf DataFieldNumberSlider
   * @inner
   */
  const [error, setError] = React.useState(true)
  /**
   * State<br/>
   * A helper text which appears under the input field.
   * It can show additional information or an error message.
   *
   * @name helperText
   * @default ''
   * @prop {string} helperText - state
   * @prop {function} setHelperText - setter
   * @type {state}
   * @memberOf DataFieldNumberSlider
   * @inner
   */
  const [helperText, setHelperText] = React.useState('')

  /**
   * State<br/>
   * A _setTimeout_ callback fired when a user stops typing in the field input element
   *
   * @name typingTimeout
   * @default undefined
   * @prop {object} typingTimeout - state
   * @prop {function} setTypingTimeout - setter
   * @type {state}
   * @memberOf DataFieldNumberSlider
   * @inner
   */
  const [typingTimeout, setTypingTimeout] = React.useState()

  /**
   * State<br/>
   * The current value of the data field.
   *
   * @name value
   * @default [value]{@link DataFieldNumberSlider}
   * @prop {string} value - state
   * @prop {function} setValue - setter
   * @type {state}
   * @memberOf DataFieldNumberSlider
   * @inner
   */
  const [value, setValue] = React.useState(props.value ? Number(props.value) : min)

  // styles
  const classes = useStyles({error: error})

  
  /**
   * Method<br/>
   * Validates if the input value is within the predefined range.
   *
   * @function
   * @arg {string} [valueToValidate=[value]{@link DataFieldNumberSlider~value}]
   * numeric value to be validated
   * @returns {bool} 
   */
  const validateValue = (valueToValidate=value) => {
    return Boolean(valueToValidate) && valueToValidate >= min && valueToValidate <= max
  }

  /**
   * Event Handler<br/>
   * **_Event:_** change slider's value.<br/>
   * **_Implementation:_**
   * updates state [value]{@link DataFieldNumberSlider~value} by the current slider's value
   */
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** change value of the input field.<br/>
   * **_Implementation:_**
   * * updates state [value]{@link DataFieldNumberSlider~value} by the current input value
   * * updates state [typingTimeout]{@link DataFieldNumberSlider~typingTimeout} using 
   * method [typingTimeoutWithInputTrigger]{@link module:Other.typingTimeoutWithInputTrigger}
   */
  const handleInputChange = (event) => {
    const newValue = event.target.value
    setValue(newValue === '' ? '' : Number(newValue))

    // check if typing is NOT finished
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    // set timeout for typing finished
    setTypingTimeout(typingTimeoutWithInputTrigger(props, newValue, validateValue(newValue)))
  }

  /**
   * Event Handler<br/>
   * **_Event:_** _mouseup_ on slider.<br/>
   * **_Implementation:_**
   * If prop _inputTriggers_ of [data]{@link DataFIeldNumberSlider} is _true_ then calls 
   * prop [onInputTrigger]{@link DataFieldNumberSlider}.
   * Otherwise calls prop [onChange]{@link DataFIeldNumberSlider}.
   */
  const handleChangeCommitted = (event, newValue) => {
    if (props.onInputTrigger && data.inputTriggers && validateValue(newValue)) {
        // input trigger
        props.onInputTrigger({[data.name]: newValue})
      } else if (props.onChange) {
        // update antrag value
        props.onChange({[data.name]: newValue})
      }
  }


  /**
   * Callback<br/>
   * Formats value's label on the slider.
   *
   * @function
   * @arg {string} v
   * slider's value
   * @returns {string}
   * result of [formatNumberWithSuffix]{@link module:datafieldUtil.formatNumberWithSuffix}
   */
  const getValueLabel = (v) => {
    return formatNumberWithSuffix(v)
   }


  /**
   * If prop [data.errorMessage]{@link DataFIeldNumberSlider.data} is defined
   * then sets its value to sate [helperText]{@link DataFIeldNumberSlider~helperText}
   * and _true_ to state [error]{@link DataFIeldNumberSlider~error}.
   * Otherwise, the values of the mentioned state are set to
   * a default helper message and result of
   * the value [validation]{@link DataFIeldNumberSlider~validateValue}
   *
   * @name useEffect
   * @function
   * @memberOf DataFIeldNumberSlider
   * @inner
   * @arg {string} data.errorMessage
   * prop [data.errorMessage]{@link DataFIeldNumberSlider.data}
   * @arg {number} value
   * state [value]{@link DataFIeldNumberSlider~value}
   */
  React.useEffect(() => {
    // check if error comes from backend
    if (Boolean(data.errorMessage)) {
      setHelperText(data.errorMessage)
      setError(true)
      return
    }

    // check value in range
    if (!validateValue(value)) {
      setHelperText(t('common:value.range') + ': ' + min + '-' + max)
      setError(true)
      return
    }

    setError(false)
    setHelperText('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data.errorMessage])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs>
          <SliderTitle
            id={`${data.name}-${id}`}
            component="div"
          >
            {data.brief}
          </SliderTitle>
          <DataFieldSlider
            className={classes.slider}
            aria-labelledby={`${data.name}-${id}`}
            valueLabelDisplay="auto"
            value={value}
            min={min}
            max={max}
            step={step}
            onChange={handleChange}
            onChangeCommitted={handleChangeCommitted}
            valueLabelFormat={getValueLabel}
          />
        </Grid>
        <Grid item>
          <Input
            className={classes.sliderInput}
            value={value}
            margin="dense"
            onChange={handleInputChange}
            error={error}
            inputProps={{
              min: min,
              max: max,
              step: step,
              type: 'number',
              'aria-labelledby': `${data.name}-${id}`,
            }}
          />
        </Grid>
      </Grid>
      <FormHelperText error={error}>
        {helperText}
      </FormHelperText>
    </React.Fragment>
  )
}

DataFieldNumberSlider.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldNumberSlider.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the numeric field
   */
  value: PropTypes.string,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input value
   */
  onInputTrigger: PropTypes.func,
}

export default DataFieldNumberSlider