import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { 
  CardContent,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { CardActive, CardTop, CardBottom, hideTime } from '../styles/cards'
import CardTitle from '../components/cardTitle'
import { PolicyDetails } from './policyDetails'
import { removePolicy, updatePolicy } from '../redux/actions'
import { executeActivity, deletePolicy, updatePolicyFields } from '../api/policy'
import CardCloseButton from '../components/closeButton'
import ExpandButton from '../components/expandButton'
import DataGroup from '../datafields/dataGroup'
import ProgressButton from '../components/progressButton'
import { getFieldValue } from '../utils'

// test imports
//import {BrokeCard} from '../debug/damageCard'


const ActionControl = withStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 240,
  }
}))(FormControl)


/**
 * This component renders a policy card with the request status _OK_.
 * @see [MapPolicyCard]{@link PolicyView.MapPolicyCard} for possible request status of a policy instance
 *
 * @component
 * @category Policy
 */
function ActivePolicy(props) {
  const {t} = useTranslation('common', 'policy')

  /**
   * Policy instance extracted from _prop_ [policy]{@link ActivePolicy}
   *
   * @name policy
   * @type {object}
   * @memberOf ActivePolicy
   * @prop {string} id
   * stringified UUID of the policy instance
   * @prop {string} policy_number
   * number of the policy
   * @prop {string} effective_date
   * effective date of the policy
   * @prop {object[]} possible_activities
   * a list of activities which are possible for the policy   
   * @prop {string} possible_activities.name
   * activity name
   * @prop {string} possible_activities.description
   * a short description of the activity
   * @prop {object[]} possible_activities.fields
   * a list of the input fields of the activity
   * @prop {object} product_line
   * the details of the product line of the policy
   * @prop {string} product_line.name
   * the product line name
   * @prop {object} product_line.attributes
   * the attributes of the product line as _name_-_vale_ pairs
   * @prop {object} attributes
   * the general attributes of the policy as _name_-_value_ pairs
   * @prop {object} insured_object
   * the details of an insured object of the policy as _ID_-_description_ pairs
   * @prop {object} premium_payer
   * a list of the premium payers of the policy as _ID_-_name_ pairs
   * @prop {object[]} clauses
   * a list of the policy clauses
   * @prop {string} clauses.name
   * the name of a clause
   * @prop {string} clauses.number
   * the number of a clause
   * @prop {string} clauses.description
   * the description of the clause (supports HTML format)
   * @prop {string} clauses.link
   * URL for the clause's details
   */
  const {policy} = props


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the policy card should be expanded.
   *
   * @name expanded
   * @default false
   * @prop {bool} expanded - state
   * @prop {function} setExpanded - setter
   * @type {state}
   * @memberOf ActivePolicy
   * @inner
   */
  const [expanded, setExpanded] = React.useState(false)

  /**
   * State<br/>
   * A boolean flag that defines the card visibility.
   * Used to animate the card appearance and closure. 
   *
   * @name visible
   * @default false
   * @prop {boolean} visible - state
   * @prop {function} setVisible - setter
   * @type {state}
   * @memberOf ActivePolicy
   * @inner
   */
  const [visible, setVisible] = React.useState(false)

  /**
   * State<br/>
   * A boolean flag that shows if a policy activity is executing now.
   * Used to disable execution requests of activities
   * if the previous request is still in progress.
   *
   * @name activityExecutes
   * @default false
   * @prop {boolean} activityExecutes - state
   * @prop {function} setActivityExecutes - setter
   * @type {state}
   * @memberOf ActivePolicy
   * @inner
   */
  const [activityExecutes, setActivityExecutes] = React.useState(false)

  /**
   * State<br/>
   * Object that holds the currently selected activity of the policy.
   * If no activity selected then _undefined_.
   *
   * @name currentActivity
   * @default undefined
   * @prop {object} currentActivity - state
   * @prop {function} setActivity - setter
   * @type {state}
   * @memberOf ActivePolicy
   * @inner
   */
  const [currentActivity, setActivity] = React.useState()

  /**
   * State<br/>
   * Object that holds the values of the input fields of the current activity in form of
   * ```javascript
   * {
   *   <fieldName>: <value>
   * }
   * ```
   *
   * @name activityValues
   * @default {}
   * @prop {object} activityValues - state
   * @prop {function} setActivityValues - setter
   * @type {state}
   * @memberOf ActivePolicy
   * @inner
   */
  const [activityValues, setActivityValues] = React.useState({})

  /**
   * Method<br/>
   * Updates state [activityValues]{@link ActivePolicy~activityValues}
   * with the values from the given activity object.
   *
   * @function
   * @arg {object} activity
   * one of the possible activitites of the current policy
   * @see [policy.possible_activitites]{@link ActivePolicy.policy} for the details of an activity object
   */
  const updateActivityValues = (activity) => {
    // get activity values from back-end
    setActivityValues({
      ...activityValues,
      ...activity.fields.filter((field) => 
        (field.fieldVisibilityType !== 2)
      ).reduce((result, field) => ({
        ...result,
        ...getFieldValue(field),
      }), {})
    })
  }
  
  /**
   * Method<br/>
   * Checks if the policy doesn't provide _at least one_ activity to execute.
   *
   * @function
   */
  const actionsNotAvailable = () => {
    if (policy.possible_activities && policy.possible_activities.length > 0) {
      return false
    }

    return true
  }  

  /**
   * Event Handler<br/>
   * **_Event:_** click expand/collapse button of the policy card.<br/>
   * **_Implementation:_**
   * sets state [expanded]{@link ActivePolicy~expanded} to its inverse value.
   */
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  /**
   * Callback<br/>
   * **_Implementation:_** deletes the policy by:
   * * removing the policy instance from the [redux store]{@link module:State~policies}
   * * calling back-end [deletePolicy]{@link module:Policy.deletePolicy}
   * to remove the policy in the back-end  
   *
   */
  const handleDeleteCard = () => {
    props.closePolicyCard(props.index)

    // delete policy in back-end store
    deletePolicy(props.user, policy.id).then(data => {
    }).catch(error => {
      console.log(error)
    })
  }

  /**
   * Method<br/>
   * Returns an activity instance from the [possible activities]{@link ActivePolicy.policy}
   * by the given name. If no activity matches the given name then returns _undefined_ 
   *
   * @function
   * @arg {string} name
   * name of a policy activity
   * @returns {object}
   */
  const getActivityByName = (name) => {
    // returns possible activity by its name
    for (const activity of policy.possible_activities) {
      if (activity.name === name) {
        return activity
      }
    }
    return undefined
  }

  /**
   * Event Handler<br/>
   * **_Event:_** change the value of an input field of an activity.<br/>
   * **_Implementation:_** updates state [activityValues]{@link ActivePolicy~activityValues}
   * with the received _newValues_.
   *
   * @arg {object} newValues
   * Object of form
   * ```javascript
   * {
   *    <inputFieldName>: <inputFieldValue>
   * }
   * ```
   */
  const handleActivityDataChanged = (newValues) => {
    setActivityValues({
      ...activityValues,
      ...newValues,
    })
  }

  /**
   * Method<br/>
   * Deactivates the current activity by:
   * * setting state [currentActivity]{@link ActivePolicy~currentActivity} to _ubdefined_
   * * setting state [activityValues]{@link ActivePolicy~activityValues} to an empty object
   */
  const clearCurrentActivity = () => {
    setActivity()
    setActivityValues({})
  }

  /**
   * Event Handler<br/>
   * **_Event:_** select an activity from the _drop-down_ list.<br/>
   * **_Implementation:_**
   * If the activity is found in [possible activitites]{@link ActivePolicy.policy} of the policy
   * then sets it to state [currentActivity]{@link ActivePolicy~currentActivity}.
   * Otherwise calls method [clearCurrentActivity]{@link ActivePolicy~clearCurrentActivity}.
   */
  const handleActivitySelect = (event) => {
    const newActivity = getActivityByName(event.target.value)
    if (newActivity === undefined) {
      clearCurrentActivity()
      return
    }

    // update state
    setActivity(newActivity)
    updateActivityValues(newActivity)
  }

  /**
   * Method<br/>
   * Validates if all the mandatory fields of the current activity are not empty.
   */
  const validateActivity = () => {
    // check if activity selected
    if (!currentActivity)
      return false

    // check if mandatory values are filled
    for (const field of currentActivity.fields) {
        if (field.isMandatory && !activityValues[field.name])
          return false
      }

    return true
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click execute button of the current activity.<br/>
   * **_Implementation:_**
   * Calls the back-end [executeActivity]{@link module:Policy.executeActivity}
   * to execute the current _activity_. If the response is successful
   * then updates the current policy instance.
   */
  const handleActivityExecution = () => {
    //console.log('Action Execution')
    setActivityExecutes(true)

    const requestData = {
      id: policy.id,
      activity: {
        name: currentActivity.name,
        fields: currentActivity.fields.map((field) => ({
          name: field.name,
          value: activityValues[field.name],
        }))
      },
    }

    executeActivity(props.user, requestData).then(data => {
      // return result
      if (currentActivity.name === "Detailauskunft") {
        // open link
        window.open(data.link, "_blank")
      } else {
        // update policy data
        props.updatePolicy(
          props.index,
          {
            request_state: "ok",
            ...data,
          }
        )
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      // update state
      setActivityExecutes(false)
      clearCurrentActivity()
    })
  }


  /**
   * Event Handler<br/>
   * **_Event:_** change the value of an input field, which prop `inputTriggers == true`.<br/>
   * **_Implementation:_** calls the back-end [updatePolicyFields]{@link module:Policy.updatePolicyFields}
   * to update the policy instance with the actual values of the input fields.
   * If the response is successful, then update the current policy instance by
   * obtained from the back-end one.
   */
  const handleInputTrigger = (newValues={}) => {

    // build request body
    const requestData = {
      id: policy.id,
      activity: currentActivity.name,
      values: {
        ...activityValues,
        ...newValues,
      },
    }

    // call update end-point
    updatePolicyFields(props.user, requestData).then(data => {
      // update policy
      props.updatePolicy(
        props.index,
        {
          request_state: "ok",
          ...data,
        }
      )
    }).catch(error => {
      console.log(error)
    }) 
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click _close_ button of the policy card.<br/>
   * **_Implementation_:**
   * sets _false_ to state [visible]{@link ActivePolicy~visible}
   * achieving a visual effect of card shrinking and disappearing. 
   */
  const handleCloseCard = () => {
    setVisible(false)
  }

  /**
   * Implements animation of the card appearance
   * by setting _true_ to state [visible]{@link ActivePolicy~visible}.
   *
   * @name useEffect
   * @function
   * @memberOf ActivePolicy
   * @variant 1
   * @inner
   */
  React.useEffect(() => {
    setVisible(true)
  }, [])

  /**
   * Updates states [currentActivity]{@link ActivePolicy~currentActivity} and
   * [activityValues]{@link ActivePolicy~activityValues}
   * from the current policy instance, when it changes.
   *
   * @name useEffect
   * @function
   * @memberOf ActivePolicy
   * @variant 2
   * @inner
   * @arg {object} policy
   * prop [policy]{@link ActivePolicy.policy}
   */
  React.useEffect(() => {
    if (Boolean(currentActivity)) {
      const updatedActivity = policy.possible_activities.filter(activity => activity.name === currentActivity.name)[0]
      
      // curent activity not found in updated policy
      if (!updatedActivity) {
        clearCurrentActivity()
        return
      }

      setActivity(updatedActivity)
      updateActivityValues(updatedActivity)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy])


  /**
   * It is an inner component of a [policy card]{@link ActivePolicy}
   * which renders the card title along with activity selection element as a drop-down list.
   *
   * @memberOf ActivePolicy
   * @inner
   */
  const RenderHeader = () => (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={4}>
          <CardTitle
            type="policy"
            name={policy.policy_number}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormGroup row>
            <ActionControl
              variant="outlined"
              size="small"
            >
              <InputLabel id={`action-${props.index}-label`}>
                {t("common:action")}
              </InputLabel>
              <Select
                labelId={`action-${props.index}-label`}
                id={`action-${props.index}`}
                value={currentActivity ? currentActivity.name :  "none"}
                onChange={handleActivitySelect}
                disabled={actionsNotAvailable()}
                label={t("common:action")}
              >
                <MenuItem value="none">
                  <em>{t("common:none")}</em>
                </MenuItem>
                {policy.possible_activities.map((activity, index) => (
                  <MenuItem key={`${activity.name}-${index}`} value={activity.name}>
                    {activity.name}
                  </MenuItem>
                ))}
              </Select>
            </ActionControl>
            <ProgressButton
              title={t('common:execute')}
              loading={activityExecutes}
              disabled={!validateActivity()}
              onClick={handleActivityExecution}
            />
          </FormGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  )


  //console.log('POLICY:')
  //console.log(props)

  return(
    <Collapse
      in={visible}
      timeout={hideTime}
      unmountOnExit
    >
      <CardActive>
          <CardTop
            action={
              <React.Fragment>
              {/* DEBUG: broke antrag
                <BrokeCard card="Policy" />
              */}

              {/* Close Button */}
                <CardCloseButton
                  onClose={handleCloseCard}
                  onDelete={handleDeleteCard}
                />

              </React.Fragment>
            }
            title={<RenderHeader />}
            subheader={policy.effective_date}
          />
          {currentActivity && 
           currentActivity.fields.filter(field => field.fieldVisibilityType === 1).length > 0 &&
            <CardContent>
              <DataGroup 
                id={policy.id}
                title={currentActivity.description}
                fields={currentActivity.fields}
                values={activityValues}
                onChange={handleActivityDataChanged}
                onInputTrigger={handleInputTrigger}
              />
            </CardContent>
          }
          <CardBottom>
            <ExpandButton
              expanded={expanded}
              onClick={handleExpandClick}
            />
          </CardBottom>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <CardContent>
              <PolicyDetails policy={policy} />
            </CardContent>
          </Collapse>
      
      </CardActive>
    </Collapse>
  )

}

ActivePolicy.propTypes = {
  /**
   * The index of the policy instance in the [redux store]{@link module:State~policies}
   */
  index: PropTypes.number,
  /**
   * The policy instance (see [policy]{@link ActivePolicy.policy})
   */
  policy: PropTypes.object,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Redux action [removePolicy]{@link module:Actions.removePolicy}
   */
  closePolicyCard: PropTypes.func,
  /**
   * Redux action [updatePolicy]{@link module:Actions.updatePolicy}
   */
  updatePolicy: PropTypes.func,
}


// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  closePolicyCard: removePolicy,
  updatePolicy: updatePolicy,
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivePolicy)
