import { apiHost } from '../../utils'

export const uriLogo = apiHost + 'api/logo/'

/**
 * Derives filename of the current company logo
 * 
 * @category Auxiliary Components
 * @subcategory Logo
 * 
 * @function
 * @arg {object} attributes
 * company attributes
 * @arg {object} attributes.logo
 * filenames of the company logos
 * @arg {string} target
 * position of the logo. Either of "_top_", "_policy_", "_antrag_" 
 */
export const getCompanyLogo = (attributes, target) => {
  if (attributes && attributes.logo) {
    return attributes.logo[target]
  }

  return undefined
}

export {default as EmblemLogo} from "./emblem"
export {default as TopBarLogo} from "./topbar"