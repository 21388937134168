import React from 'react'
import PropTypes from 'prop-types'
import { 
  Button,
  CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
  },

  button: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -theme.spacing(4) / 2,
    marginLeft: -theme.spacing(4) / 2,
  }
}))


/**
 * This component renders a button with a _loading_ state (emulates loading, retrieving data etc).
 * While the button is in the _loading_ state (prop _loading_=_true_), it is disabled 
 * (prevent multiple pressing) and renders a circular progress.
 * 
 * @component
 * @category Auxiliary Components
 */
function ProgressButton(props) {
  const {title, loading, disabled, onClick } = props
  const classes = useStyles()

  return(
    <div className={classes.container}>
      <Button 
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={disabled || loading}
      >
        {title}
      </Button>
      {loading && <CircularProgress size={24} className={classes.button} />}
    </div>
  )
}

ProgressButton.propTypes = {
  /**
   * Title of the button
   */
  title: PropTypes.string,
  /**
   * A boolean flag which shows if the button is in loading state
   */
  loading: PropTypes.bool,
  /**
   * A boolean flag which show if the buton is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback fired when the button is clicked.
   */
  onClick: PropTypes.func,
}

export default ProgressButton
