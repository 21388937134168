import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import htmlParse from 'html-react-parser'
import SearchField from './searchField'
import EnhancedTable from './enhancedTable'
import DataFieldSelect from './selectField'
import MappedImage from './mappedImage'
import DocumentTable from './documentTables/documentTable'
import AttachmentTable from './documentTables/attachmentTable'
import LinearChart from './charts'
import ExpandButton from '../components/expandButton'
import { parseJSONString } from '../utils'
import DataFieldText from './textField'
import DataFieldLongText from './textBoxField'
import DataFieldTextSlider from './textFieldSlider'
import DataFieldNumber from './numericField'
import DataFieldNumberRange from './numericFieldRange'
import DataFieldNumberSlider from './numericFieldSlider'
import DataFieldDate from './dateField'
import DataFieldSwitch from './boolFieldSwitch'
import DataFieldRadio from './boolFieldRadio'
import TooltipField from './tooltipField'


// Styles
const useStyles = makeStyles((theme) => ({
  inputGroup: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    backgroundColor: props => props.backgroundColor ? props.backgroundColor : "#fff",
  },

  inputGroupContainer: {
    margin: 0,
    width: "100%",
  },

  inputGroupSubtitle: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },

  flagLabel: {
    color: theme.palette.text.primary,
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
    fontSize: "0.875rem",
  },
}))



/*
** Data Field Mapper
*/
/**
 *
 * It is a mapper component which returns a data field object based on the type
 * of a data field.
 * | Field Type | PossibleField Component         |
 * | -----------|-------------------------------- |
 * | Text       | {@link DataFieldText}, {@link DataFieldTextSlider}, {@link DataFieldSelect} |
 * | TextBox    | {@link DataFieldLongText}       |
 * | Zahl       | {@link DataFieldNumber}, {@link DataFieldNumberRange}, {@link DataFieldNumberSlider} |
 * | Datum      | {@link DataFieldDate}           |
 *
 * @prop {object} props
 * @prop {object} props.data - The data of a field
 *
 * @memberOf DataGroup
 */
function DataField(props) {
  const {data} = props

  //console.log('DATA FIELD')
  //console.log(props)

  // ranged data
  if (data.inputRange && data.inputRange.length > 0) {
    // numeric
    if (data.fieldDataType === "Zahl") {
      // regular number range
      if (data.inputRange[0] === "range") {
        return <DataFieldNumberRange {...props} />
      }
      // slider number range
      if (data.inputRange[0] === "slider") {
        return <DataFieldNumberSlider {...props} />
      }
    }

    
    // text slider
    if (data.inputRange[0] === "slider") {
      return <DataFieldTextSlider {...props} />
    }
    // text drop-down
    return <DataFieldSelect {...props} />
  }
  
  // not ranged data
  switch (data.fieldDataType) {
    case "Zahl":
      return <DataFieldNumber {...props} />
    case "Datum":
      return <DataFieldDate {...props} />
    case "TextBox":
      return <DataFieldLongText {...props} />
    default:
      return <DataFieldText {...props} />
  }
}


/**
 * Renders a group of data fields as a card.
 *
 * @component
 * @category Data Fields
 */
function DataGroup(props) {
  const {title, fields, values, ...commonProps} = props
  const classes = useStyles({backgroundColor: props.backgroundColor})

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the group crad should be expanded.
   *
   * @name expanded
   * @default true
   * @prop {bool} expanded - state
   * @prop {function} setExpanded - setter
   * @type {state}
   * @memberOf DataGroup
   * @inner
   */
  const [expanded, setExpanded] = React.useState(true)
  /**
   * State<br/>
   * A list of titles of subgroups within the group card.
   *
   * @name subtitles
   * @default []
   * @prop {string[]} subtitles - state
   * @prop {function} setSubtitles - setter
   * @type {state}
   * @memberOf DataGroup
   * @inner
   */
  const [subtitles, setSubtitles] = React.useState([])

  /**
   * Sets state [subtitles]{@link DataGroup~subtitles} to 
   * prop [subtitles]{@link DataGroup} when the component is mounted.
   *
   * @name useEffect
   * @function
   * @memberOf DataGroup
   * @inner
   * @arg {string[]} subtitles
   * prop [subtitles]{@link DataGroup}
   */
  React.useEffect(() => {
    setSubtitles(props.subtitles ? [undefined, ...props.subtitles] : [undefined])
  }, [props.subtitles])

  
  /**
   * Method<br/>
   * Calculates the size of a data field within
   * [Material UI grid]{@link https://material-ui.com/components/grid/} 
   *
   * @function
   * @arg {string} screen
   * a breakpoint of screen width:
   * | **Value** | **Screen width** |
   * | --------- | ---------------- |
   * | xs    | < 960 px      |
   * | md    | 960 - 1280 px |
   * | lg    | >= 1280 px    |
   * 
   * @returns {number} 
   */
  const size = (screen) => {
    if (title.includes('suchen')) {
      return 12
    }

    switch (screen) {
      case 'lg':
        return 3
      case 'md':
        return 4
      default:
        return 12
    }
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click expand/collapse button.<br/>
   * **_Implementation:_**
   * sets state [expanded]{@link DataGroup~expanded} to its inverse value.
   */
  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  //console.log('DATA GROUP:')
  //console.log(fields.filter((field) => (field.fieldDataType === "SearchEndPoint")))
  //console.log(props)
  //console.log(subtitles)

  return (
    <Paper 
      classes={{root: classes.inputGroup}}
      elevation={2}
    >

      <Grid
        container
        spacing={1}
      >

        {/* Expand Button */}
        <Grid item>
          <ExpandButton
            expanded={expanded}
            onClick={handleExpanded}
            size="small"
          />
        </Grid>

        {/* Title */}
        <Grid item>
          <Typography 
            gutterBottom
            variant="h5"
            component="p"
          >
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Collapse
        in={expanded}
        timeout="auto"
      >

        {/* subsections */}
        {subtitles.map((subtitle, index) => (
          <Grid
            key={`${props.id}-section-${index}`}
            container
            direction="column"
          >

            {/* Subtitle */}
            {subtitle && 
              <Grid
                item
                xs={12}
              >
                <Typography 
                  className={classes.inputGroupSubtitle}
                  gutterBottom
                  variant="h5"
                  component="p"
                >
                  {subtitle}
                </Typography>
              </Grid>
            }

            {/* Flags */}
            <Grid 
              classes={{root: classes.inputGroupContainer}}
              item
              container
              spacing={2}
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldDataType === "Flag" && field.fieldVisibilityType === 1
              )).map((field) => (
                <Grid
                  item
                  key={field.name}
                  xs={6}
                  md={4}
                  lg={3}
                >
                  <TooltipField
                    tooltip={field.tooltip}
                  >
                    <div>
                      <DataFieldSwitch 
                        {...commonProps}
                        data={field}
                        value={values[field.name]}
                      />
                    </div>
                  </TooltipField>
                </Grid>
              ))}
            </Grid>

            {/* Input Fields */}
            <Grid 
              classes={{root: classes.inputGroupContainer}}
              item
              container
              spacing={2}
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldDataType !== "Flag" && field.fieldVisibilityType === 1 &&
                field.fieldDataType !== "SearchEndPoint" && field.fieldDataType !== "Table" &&
                field.fieldDataType !== "FlagWithOptions" && field.fieldDataType !== "RadioFlagWithOptions"
              )).map((field) => (
                <Grid 
                  item
                  key={field.name}
                  xs={size('xs')}
                  md={size('md')}
                  lg={field.fieldDataType === "TextBox" ? 2*size('lg') : size('lg')}
                >
                  <TooltipField
                    tooltip={field.tooltip}
                  >
                    <div>
                      <DataField
                        {...commonProps}
                        data={field}
                        value={values[field.name]}
                      />
                    </div>
                  </TooltipField>
                </Grid>
              ))}
            </Grid>

            {/* Search Fields */}
            <Grid 
              classes={{root: classes.inputGroupContainer}}
              item
              container
              spacing={2}
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldDataType === "SearchEndPoint" && field.fieldVisibilityType === 1
              )).map((field) => (
                <Grid 
                  item
                  key={field.name}
                  xs={12}
                >
                  <SearchField
                    {...commonProps}
                    data={field}
                    value={values[field.name]}
                  />
                </Grid>
              ))}
            </Grid>

            {/* Flag with Options */}
            <Grid 
              classes={{root: classes.inputGroupContainer}}
              item
              container
              spacing={2}
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldVisibilityType === 1 &&
                (field.fieldDataType === "FlagWithOptions" || field.fieldDataType === "RadioFlagWithOptions")
              )).map((field) => (
                <React.Fragment key={field.name}>
                  <Grid 
                    item
                    xs={12}
                  >
                    <TooltipField
                      tooltip={field.tooltip}
                    >
                      <div>
                        {field.fieldDataType === "FlagWithOptions" ? (
                          <DataFieldSwitch 
                            {...commonProps}
                            data={field}
                            value={values[field.name]}
                          />
                        ) : (
                          <DataFieldRadio
                            {...commonProps}
                            data={field}
                            value={values[field.name]}
                          />
                        )}
                      </div>
                    </TooltipField>
                  </Grid>
                  <Grid item  xs={12}>
                    <Collapse
                      in={field.fieldDataType.includes("WithOptions") && Boolean(values[field.name])}
                      timeout="auto"
                      
                    >
                      <Grid container spacing={2}>
                        {Boolean(field.relatedFields) && field.relatedFields.map((optionField) => (
                          <Grid 
                            item
                            key={optionField.name}
                            xs={size('xs')}
                            md={size('md')}
                            lg={optionField.fieldDataType === "TextBox" ? 2*size('lg') : size('lg')}
                          >
                            <TooltipField
                              tooltip={optionField.tooltip}
                            >
                              <div>
                                <DataField
                                  {...commonProps}
                                  data={optionField}
                                  value={values[optionField.name] ? values[optionField.name] : null}
                                />
                              </div>
                            </TooltipField>
                          </Grid>
                        ))}
                      </Grid>
                    </Collapse>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>

            {/* Table Fields */}
            <Grid 
              classes={{root: classes.inputGroupContainer}}
              item
              container
              spacing={2}
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldDataType === "Table" && field.fieldVisibilityType === 1
              )).map((field) => (
                <Grid 
                  item
                  key={field.name}
                  xs={12}
                >
                  <EnhancedTable
                    name={field.name}
                    title={field.brief}
                    data={parseJSONString(field.value)}
                    value={values[field.name]}
                    onChange={props.onGlobalChange}
                    updateParent={props.updateAntrag}
                    onCloseActivity={props.onCloseActivity}
                  />
                </Grid>
              ))}
            </Grid>

            {/* Output Fields */}

            {/* Text */}
            <Grid item>
              <Table>
                <TableBody>
                  {fields.filter((field) => (
                    field.subsection === subtitle && field.fieldVisibilityType === 2 && field.fieldDataType === "Text"
                  )).map((field) => (
                    <TooltipField
                      key={field.name}
                      tooltip={field.tooltip}
                    >
                      <TableRow hover>
                        <TableCell>{field.brief}</TableCell>
                        <TableCell>
                          {field.value && htmlParse(field.value)}
                        </TableCell>
                      </TableRow>
                    </TooltipField>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            {/* Mapped Images */}
            <Grid
              className={classes.inputGroupContainer}
              item
            >
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldVisibilityType === 2 && field.fieldDataType === "Image"
              )).map((field) => (
                <MappedImage
                  key={field.name}
                  name={field.name}
                  title={field.brief}
                  image={field.icon}
                  tooltip={field.tooltip}
                  data={parseJSONString(field.value)}
                />
              ))}
            </Grid>

            {/* Charts */}
            <Grid item>
              {fields.filter((field) => (
                field.subsection === subtitle && field.fieldVisibilityType === 2 && field.fieldDataType === "Chart"
              )).map((field) => (
                <LinearChart
                  key={field.name}
                  name={field.name}
                  title={field.brief}
                  tooltip={field.tooltip}
                  data={parseJSONString(field.value)}
                />
              ))}
            </Grid>

          {/* Document Fields */}
            <Grid item container spacing={2}> 
              <Grid item xs={12}>
                {fields.filter((field) => (
                  field.subsection === subtitle && field.fieldVisibilityType === 2 && field.fieldDataType === "Documents"
                )).map((field) => (
                  <DocumentTable
                    key={field.name}
                    parentId={props.id}
                    name={field.name}
                    title={field.brief}
                    data={parseJSONString(field.value)}
                    updateParent={props.onInputTrigger}
                  />
                ))}
              </Grid>

              <Grid item xs={12}>
                {fields.filter((field) => (
                  field.subsection === subtitle && field.fieldVisibilityType === 2 && field.fieldDataType === "Attachments"
                )).map((field) => (
                  <AttachmentTable
                    key={field.name}
                    name={field.name}
                    title={field.brief}
                    data={parseJSONString(field.value)}
                    updateParent={props.onInputTrigger}
                  />
                ))}
              </Grid>
            </Grid>

          </Grid>
        ))}

        {/*actions*/}
      </Collapse>
    </Paper>
  )
}

DataGroup.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Title of the group of data fields
   */
  title: PropTypes.string,
  /**
   * List of subtitles within the group
   */
  subtitles: PropTypes.arrayOf(PropTypes.string),
  /**
   * List of field data within the group
   */
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    brief: PropTypes.string,
    inputRange: PropTypes.array,
    inputTriggers: PropTypes.bool,
    errorMessage: PropTypes.string,
  })).isRequired,
  /**
   * Values of the fields within the group
   */
  values: PropTypes.objectOf(PropTypes.any),
  /**
   * Background color of the group card
   */
  backgroundColor: PropTypes.string,
  /**
   * Callback fired to change values of data fields
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to change values within the parent instance
   */
  onGlobalChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input values
   */
  onInputTrigger: PropTypes.func,
  /**
   * Callback fired to close the current activity of the parent instance
   */
  onCloseActivity: PropTypes.func,
  /**
   * Callback fired to update the parent instance of product offer on the back-end
   */
  updateAntrag: PropTypes.func,
}

export default DataGroup