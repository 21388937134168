import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import { hideTime } from '../styles/cards'


/**
 * This component provides a card close button with the functionality of:
 * * animation of the card closure
 * * removing the card instance from the front-end and back-end apps 
 *
 * @component
 * @category Auxiliary Components
 */
function CardCloseButton(props) {
  const {t} = useTranslation('common')

  /**
   * Event Handler<br/>
   * **_Event:_** click close button<br/>
   * **_Implementation:_** calls prop [onClose]{@link CardCloseButton} and
   * sets a timer to call prop [onDelete]{@link CardCloseButton}.
   */
  const handleClose = () => {
    props.onClose()
    setTimeout(() => {props.onDelete()}, hideTime)
  }

  return (
    <Tooltip title={t('common:close')}>
      <IconButton 
        aria-label="close"
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </Tooltip>
  )
}

CardCloseButton.propTypes = {
  /**
   * Callback fired to animate closure of the card.
   */
  onClose: PropTypes.func,
  /**
   * Callback fired to remove the card both in the front-end 
   * ([redux store]{@link module:State}) and the the back-end.
   */
  onDelete: PropTypes.func,
}

export default CardCloseButton