import React from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Link,
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import MoreButton from '../../components/expandButton'

// styles
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },

  companyBar: {
    backgroundColor: theme.palette.primary.light,
  },

  actionLink: {
    marginRight: theme.spacing(1)
  }, 

  actionCol: {
    maxWidth: 200,
  },
}))


/**
 * This component renders a company card in the admin panel.
 * A company card displays a list of the child companies (if available) and
 * a list of the users with appropriate actions.
 *
 * @component
 * @category Views
 * @subcategory Admin View
 */
function CompanyCard(props) {
  const classes = useStyles()
  const {t} = useTranslation("admin", "common")


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the company card should be expanded.
   *
   * @name expanded
   * @default false
   * @prop {bool} expanded - state
   * @prop {function} setExpanded - setter
   * @type {state}
   * @memberOf CompanyCard
   * @inner
   */
  const [expanded, setExpanded] = React.useState(false)

  
  /**
   * Method<br/>
   * Truncates the given string if it is longer then given length.
   *
   * @arg {string} string
   * the string to be truncated
   * @arg {number} [length=30]
   * the length of the truncated string
   */
  const truncateString = (string, length=30) => {
    if (string.length <= length) {
      return string
    }

    return string.substring(0, length) + '...'
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Add User_' button.<br/>
   * **_Implementation:_**
   * opens [user dialog]{@link UserDialog} with _undefined_ user's instance.
   */
  const handleAddUser = () => {
    props.openDialog('user', props.id)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Edit_' action of a user.<br/>
   * **_Implementation:_**
   * opens [user dialog]{@link UserDialog} with the selected user's instance.
   */
  const handleEditUser = (user) => {
    props.openDialog('user', props.id, user)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Remove_' action of a user.<br/>
   * **_Implementation:_**
   * pushes action '_remove_' along with the selected user's email
   * to prop [actions.user]{@link CompanyCard}.
   */
  const handleRemoveUser = (user) => {
    const payload = {
      email: user.email,
      companyId: props.id,
    }

    props.actions.user('remove', payload)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Edit_' action of a child company.<br/>
   * **_Implementation:_**
   * opens [company dialog]{@link CompanyDialog} with the selected company's instance.
   */
  const handleEditCompany = (company) => {
    props.openDialog('company', props.id, company)
  }


  //console.log('Company Card:')
  //console.log(props)

  return (
    <Card>
      <CardHeader
        action={
          <MoreButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        }
        title={props.name}
        subheader={props.displayedName}
      />
      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent>

          {/* Child Companies */}
          {props.childCompanies.length > 0 &&
            <React.Fragment>
              <Toolbar
                className={classes.companyBar}
                variant="dense"
              >
                <Typography
                  id="tableTitle"
                  variant="h6"
                  component="div"
                  className={classes.title}
                >
                  {t("admin:company.children")}
                </Typography>
              </Toolbar>
              <Table>
                <colgroup>
                  <col style={{width:'25%'}}/>
                  <col style={{width:'55%'}}/>
                  <col style={{width:'20%'}}/>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("admin:company")}</TableCell>
                    <TableCell>{t("admin:attributes")}</TableCell>
                    <TableCell className={classes.actionCol}>{t("admin:actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.childCompanies.map((company) => (
                    <TableRow key={`${props.id}-${company.id}`}>
                      <TableCell>{company.name}</TableCell>
                      <TableCell>
                        {truncateString(company.attributes)}
                      </TableCell>
                      <TableCell>
                        <Link
                          classes={{root: classes.actionLink}}
                          component="button"
                          variant="body2"
                          onClick={() => handleEditCompany(company)}
                        >
                          {t("common:edit")}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </React.Fragment>
          }

          {/* Users */}
          <Toolbar
            className={classes.companyBar}
            variant="dense"
          >
            <Typography
              id="tableTitle"
              variant="h6"
              component="div"
              className={classes.title}
            >
              {t("admin:user.plural")}
            </Typography>
            <Tooltip title={t("admin:user.add")}>
              <IconButton
                aria-label="add-user"
                onClick={handleAddUser}
              >
                <PersonAddIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Table>
            <colgroup>
              <col style={{width:'25%'}}/>
              <col style={{width:'20%'}}/>
              <col style={{width:'35%'}}/>
              <col style={{width:'20%'}}/>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>{t("admin:user")}</TableCell>
                <TableCell>{t("admin:roles")}</TableCell>
                <TableCell>{t("admin:attributes")}</TableCell>
                <TableCell>{t("admin:actions")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.users.map((user, index) => (
                <TableRow key={`${props.id}-${index}`}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.roles.join(', ')}
                  </TableCell>
                  <TableCell>
                    {truncateString(user.attributes)}
                  </TableCell>
                  <TableCell>
                    <Link
                      classes={{root: classes.actionLink}}
                      component="button"
                      variant="body2"
                      onClick={() => handleEditUser(user)}
                    >
                      {t("common:edit")},
                    </Link>
                    <Link
                      classes={{root: classes.actionLink}}
                      component="button"
                      variant="body2"
                      onClick={() => handleRemoveUser(user)}
                    >
                      {t("common:remove")}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </CardContent>
      </Collapse>
    </Card>
  )
}

CompanyCard.propTypes = {
  /**
   * ID of the current company
   */
  id: PropTypes.string.isRequired,
  /**
   * Name of the current company
   */
  name: PropTypes.string,
  /**
   * Brief of the current company
   */
  displayedName: PropTypes.string,
  /** 
   * List of the child companies
   */
  childCompanies: PropTypes.arrayOf(PropTypes.object),
  /**
   * Action callbacks for users and child companies
   */
  actions: PropTypes.shape({
    user: PropTypes.func,
    company: PropTypes.func,
  }),
  /**
   * Callback fired to open a dialog
   */
  openDialog: PropTypes.func,
}

export default CompanyCard
