import React from 'react'
import PropTypes from 'prop-types'
import {
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import { DataFieldFormControl } from './styled'

/**
 * Renders data field of type _Zahl_ without predefined range.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Numeric
 */
function DataFieldNumber(props) {
  
  /**
   * Field data extracted from _prop_ [data]{@link DataFieldNumber}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldNumber
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {number} fieldVisibilityType
   * the type of the field visibility:
   * * 1 - regular input field
   * * 2 - disabled (read-only) field
   * @prop {bool} [isMandatory]
   * a boolean flag that shows if the data field is mandatory
   * @prop {string} [errorMessage]
   * an error message related to the field that comes from the back-end
   */
  const {id, data, value } = props


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the field holds an invalid value.
   *
   * @name error
   * @default false
   * @prop {bool} error - state
   * @prop {function} setError - setter
   * @type {state}
   * @memberOf DataFieldNumber
   * @inner
   */
  const [error, setError] = React.useState(false)


  /**
   * Sets _false_/_true_ to sate [error]{@link DataFieldNumber~error} if
   * prop [data.errorMessage]{@link DataFieldNumber.data} is empty/not empty.
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldNumber
   * @inner
   * @arg {string} data.errorMessage
   * prop [data.errorMessage]{@link DataFieldNumber.data}
   */
  React.useEffect(() => {
    setError(Boolean(data.errorMessage))
  }, [data.errorMessage])

  /**
   * Event Handler<br/>
   * **_Event:_** change input value.<br/>
   * **_Implementation:_**
   * removes all non-digit characters from the new value then
   * calls prop [_onChange_]{@link DataFieldNumber} to update value of the field
   */
  const handleChange = (event) => {
    const newValue = event.target.value
    const re = /^[0-9\b]+$/

    if (newValue === '' || re.test(newValue)) {
      props.onChange({[data.name]: newValue})
    }
  }

  return (
    <DataFieldFormControl
      variant="outlined"
      size="small"
      fullWidth
      required={data.isMandatory}
      disabled={data.fieldVisibilityType === 2}
      error={error}
    >
      <InputLabel htmlFor={`${data.name}-${id}`}>
        {data.brief}
      </InputLabel>
      <OutlinedInput
        id={`${data.name}`}
        value={value ? value : ""}
        onChange={handleChange}
        label={data.brief}
      />
      <FormHelperText>
        {data.errorMessage}
      </FormHelperText>
    </DataFieldFormControl>
  )
}

DataFieldNumber.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldNumber.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the numeric field
   */
  value: PropTypes.string,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func.isRequired,
}

export default DataFieldNumber
