import React from 'react'
import PropTypes from 'prop-types'
import { uriLogo } from '../logo'


/**
 * This component renders the app's logo
 * 
 * @component
 * @category Auxiliary Components
 * @subcategory Logo
 */
function EmblemLogo(props) {

  /**
   * Method<br/>
   * Derives the URI to the logo image in the back-end.
   * 
   * @returns {string}
   */
  const getSrc = () => {
    if (props.logo) 
      return uriLogo + props.logo

    if (props.target)
      return `${uriLogo}default/${props.target}` 

    return uriLogo + "default/default"
  }

  return (
    <img
      src={getSrc()}
      height={props.size}
      alt={`${props.target}-Logo`}
    />
  )
}

EmblemLogo.propTypes = {
  /**
   * Filename of the logo in the back-end
   */
  logo: PropTypes.string,
  /**
   * The target directory to search for the logo in the back-end 
   */
  target: PropTypes.string,
  /**
   * The size of the logo
   */
  size: PropTypes.number,
}

export default EmblemLogo
