import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { DropzoneArea } from 'material-ui-dropzone'
import DataFieldSelect from '../datafields/selectField'
import { uploadFiles } from '../api/general'
import { useSnackbar } from 'notistack'


// styles
const useStyles = makeStyles({
  filePreviewArea: {
    display: "flex",
    justifyContent: "center",
  },
})


/**
 * This component renders a dialog with a file drop-zone
 * ([material-ui-dropzone]{@link https://www.npmjs.com/package/material-ui-dropzone})
 * to upload files to the system.
 * 
 * @component
 * @category Auxiliary Components
 */
function FileUploadDialog(props) {
  const {t} = useTranslation('upload', 'attachment', 'common')
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * An object of the file to be uploaded
   *
   * @name file
   * @default undefined
   * @prop {object} file - state
   * @prop {function} setFile - setter
   * @type {state}
   * @memberOf FileUploadDialog
   * @inner
   */
  const [file, setFile] = React.useState()

  /**
   * State<br/>
   * Type of the file to be uploaded
   *
   * @name fileType
   * @default undefined
   * @prop {string} fileType - state
   * @prop {function} setFileType - setter
   * @type {state}
   * @memberOf FileUploadDialog
   * @inner
   */
  const [fileType, setFileType] = React.useState()


  /**
   * Inner Component<br/>
   * Renders _Close_ button on an alert toast
   */
  const closeToast = (key) => (
    <IconButton onClick={() => {closeSnackbar(key)}}>
      <CloseIcon />
    </IconButton>
  )
  

  /**
   * Method<br/>
   * Derives the text of an alert toast.
   * 
   * @arg {string} alertType
   * Alert type. Either of '_add_', '_remove_', '_success_'
   * @arg {string} [fileName]
   * Name of the file
   * @returns {string}
   * 
   */
  const getAlertMessage = (alertType, fileName=null) => {
    return t(`upload:alert.${alertType}`)
      .replace(
        // eslint-disable-next-line
        "${fileName}",
        (fileName ? fileName : file.name),
      )
  }


  /**
   * Event Handler<br/>
   * **_Event:_** Add a file (drop or select) to the drop-zone.<br/>
   * **_Implementation:_**<br/>
   * Sets the file to state [file]{@link FileUploadDialog~file}.
   */
  const handleFileAdd = (files) => {
    setFile(files[0])
  }

  /**
   * Event Handler<br/>
   * **_Event:_** Select a type of the file from the drop-down list.<br/>
   * **_Implementation:_**<br/>
   * Sets the selected _type_ to state [fileType]{@link FileUploadDialog~fileType}.
   */
  const handleTypeChange = (value) => {
    setFileType(value.fileType)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** Click _Cancel_ button.<br/>
   * **_Implementation:_**<br/>
   * Calls prop [onClose]{@link FileUploadDialog} to close the dialog.
   */
  const handleClose = () => {
    props.onClose()
  }

  /**
   * Event Handler<br/>
   * **_Event:_** Click _Upload_ button.<br/>
   * **_Implementation:_**<br/>
   * Pushes the current file to the back-end ([uploadFiles]{@link module:General.uploadFiles}).
   * If the file is successfully uploaded then closes the drop-zone and shows a success toast.
   */
  const handleSubmit = () => {
    uploadFiles(props.user, props.parentId, fileType, file).then(data => {
      enqueueSnackbar(
        getAlertMessage("success"),
        {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'left',
            vertical: 'bottom',
          },
          autoHideDuration: 5000,
          preventDuplicate: true,
          action: closeToast,
        },
      )
      props.onUpload()
      props.onClose()
    }).catch(error => {
      console.log('Upload: Error')
      console.log(error)
    })
  }

  return(
    <Dialog
      fullWidth
      open={props.open}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <DropzoneArea
              previewGridClasses={{container: classes.filePreviewArea}}
              filesLimit={1}
              dropzoneText={t('upload:dropzone')}
              onChange={handleFileAdd}
              getFileAddedMessage={(fileName) => getAlertMessage("add", fileName)}
              getFileRemovedMessage={(fileName) => getAlertMessage("remove", fileName)}
            />
          </Grid>

          {props.withFileType &&
            <Grid item xs={12}>
              <DataFieldSelect
                id={props.parentId}
                value={fileType}
                data={{
                  name: 'fileType',
                  brief: t('attachment:type'),
                  inputRange: ['async', 'fileType'],
                  isMandatory: true,
                }}
                onChange={handleTypeChange}
              />
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t("common:cancel")}
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={!Boolean(file)}
        >
          {t("upload:caption")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FileUploadDialog.propTypes = {
  /**
   * A boolean flag which shows if the drop-zone should be opened
   */
  open: PropTypes.bool,
  /**
   * A boolean flag which shows if the type of the file should be defined 
   */
  withFileType: PropTypes.bool,
  /**
   * ID of the current active instance of policy or product offer
   * (defined if the file should be uploaded within a policy or product offer)
   */
  parentId: PropTypes.string,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Callback fired when the file is successfully uploaded 
   */
  onUpload: PropTypes.func,
  /**
   * Callback fired to close the file drop-zone
   */
  onClose: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(FileUploadDialog)