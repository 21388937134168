import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { getBadgeTypes, makeBadgeSeen } from '../../api/gamification'
import { updateUser } from '../../redux/actions'
import confetti from 'canvas-confetti'
import BadgeImage from './badgeImg'
import Badge from './badge'

// styles
const useStyles = makeStyles((theme) => ({
  badgeContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  badgePaper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    width: 300,
  },
}))


/**
 * Method<br/>
 * Launches confetti animation implemented with 
 * [canvas-confetti]{@link https://www.npmjs.com/package/canvas-confetti}
 *
 * @function
 * @memberOf BadgeView
 */
const launchConfetti = () => {

  const endTime = Date.now() + 2000
  const colors = ['#bb0000', '#0000bb', '#00bb00', '#ffffff']

  const frame = () => {
      confetti({
        particleCount: 3,
        angle: 60,
        spread: 55,
        origin: { 
          x: 0,
          y: 0.7
        },
        colors: colors,
      })

      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: {
          x: 1,
          y: 0.7,
        },
        colors: colors,
      })

      if (Date.now() < endTime) {
        requestAnimationFrame(frame)
      }
    }

    requestAnimationFrame(frame)

}

/**
 * **PoLZy** gamification feature allows users to achieve badges for completion the predefined goals.  
 * This component renders a view which:
 * * displays all possible categories to achieve a badge along with the condition for the next level badge,
 * * displays the badges achieved by the current user,
 * * congratulates the current user with achieving a new badge.
 *
 * @component
 * @category Views
 */
function BadgeView(props) {
  const classes = useStyles()
  const {t} = useTranslation('gamification')


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A list of available [badge types]{@link Badge.type}
   *
   * @name badgeTypes
   * @default []
   * @prop {object[]} badgeTypes - state
   * @prop {function} setBadgeTypes - setter
   * @type {state}
   * @memberOf BadgeView
   * @inner
   */
  const [badgeTypes, setBadgeTypes] = React.useState([])

  /**
   * State<br/>
   * Instances related to the currently selected badge.
   *
   * @name currentBadge
   * @default []
   * @prop {object} currentBadge - state
   * @prop {object} currentBadge.badge
   * instance of the current [badge]{@link Badge.badge}
   * @prop {object} currentBadge.type
   * instance of a [type]{@link Badge.type} of the current badge
   * @prop {function} setCurrentBadge - setter
   * @type {state}
   * @memberOf BadgeView
   * @inner
   */
  const [currentBadge, setCurrentBadge] = React.useState({})

  /**
   * State<br/>
   * A boolean flag that signals if the current badge should be opened in an overlay view.
   *
   * @name openBadge
   * @default false
   * @prop {bool} openBadge - state
   * @prop {function} setOpenBadge - setter
   * @type {state}
   * @memberOf BadgeView
   * @inner
   */
  const [openBadge, setOpenBadge] = React.useState(false)

  /**
   * When the component is mounted, calls the back-end
   * [getBadgeTypes]{@link module:Gamification.getBadgeTypes}
   * to retrieve types of the badges, which are available for the current company.
   * Then sets the obtained list of badge types to state 
   * [badgeTypes]{@link BadgeView~badgeTypes}, if the response is successful.
   *
   * @name useEffect
   * @function
   * @memberOf BadgeView
   * @inner
   */
  React.useEffect(() => {
    getBadgeTypes(props.user).then((data) => {
      setBadgeTypes(data)
    }).catch(error => {
      setBadgeTypes([])
      console.log(error)
    }).finally(() => {
      props.onBadgesUpdated()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  /**
   * Method<br/>
   * If the current user posses a badge of the given type,
   * then returns it. Otherwise returns an empty object.
   * 
   * @arg {string} type
   * name of a badge type
   * @returns {object} [badge instance]{@link Badge.badge} 
   */
  const getBadgeByType = (type) => {
    const result = props.user.badges.filter(badge => badge.type === type.name)

    if (result.length === 0) {
      return {}
    }
    for (var i = 0; i < result.length; i++) {
        if (result[i].isSeen === false) {
            return result[i]
        }
    }
    return result[result.length - 1]
  }


  /**
   * Event Handler<br/>
   * **_Event:_** click a badge item within a grid.<br/>
   * **_Implementation:_**<br/>
   * Opens the selected badge in overlay. If the badge was not seen before 
   * then also launches [confetti animation]{@link BadgeView.launchConfetti}
   */
  const handleBadgeClick = (target) => {
    setCurrentBadge(target)
    setOpenBadge(true)

    // update seen prop
    if (target.badge.isSeen === false) {
      makeBadgeSeen(props.user, target).then(data => {
        props.updateUserBadges(data)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click outside a badge overlay.<br/>
   * **_Implementation:_**<br/>
   * Sets _false_ to state [openBadge]{@link BadgeView~openBadge} to
   * close the current badge overlay view.
   */
  const handleCloseBadge = () => {
    setOpenBadge(false)
  }

  if (openBadge && currentBadge.badge.isSeen === false) {
    launchConfetti()
  }

  //console.log('Badge View')
  //console.log(props)

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {badgeTypes.map(type => (
          <Grid
            key={type.name}
            item
            xs={3}
          >
            <Badge
              type={type}
              badge={getBadgeByType(type)}
              onClick={handleBadgeClick}
            />
          </Grid>
        ))}
      </Grid>

      {/* Badge Overlay */}
      <Modal
        aria-labelledby="badge-title"
        aria-describedby="badge-description"
        className={classes.badgeContainer}
        open={openBadge}
        onClose={handleCloseBadge}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openBadge}>
          <div className={classes.badgePaper}>
            <BadgeImage {...currentBadge.badge} overlay />
            {currentBadge.type &&
              <React.Fragment>
                <Typography
                  id="badge-title"
                  variant="h6"
                  component="div"
                >
                  {currentBadge.type.title}
                </Typography>
                <Typography
                  id="badge-description"
                  variant="subtitle2"
                  component="div"
                >
                  {currentBadge.badge.type ? (
                    currentBadge.badge.next_level ? (
                      currentBadge.type.description[currentBadge.badge.next_level]
                    ) : ( 
                      t('gamification:completed')
                    )) : (
                      currentBadge.type.description['lowest']
                    )
                  }
                </Typography>
              </React.Fragment>
            }
          </div>
        </Fade>
      </Modal>

    </React.Fragment>
  )
}

BadgeView.propTypes = {
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Redux action [updateUser]{@link module:Action~updateUser}
   */
  updateUserBadges: PropTypes.func,
  /**
   * Callback fired when the badge types are retrieved from the back-end
   */
  onBadgesUpdated: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  updateUserBadges: updateUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(BadgeView)