import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Checkbox,
  TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { validateEmail, validateJSONString } from '../../utils'

// styles
const useStyles = makeStyles((theme) => ({
  formItem: {
    marginBottom: theme.spacing(2),
  },
}))


/**
 * This component renders a dialog as a modal window
 * to create or edit a user in the back-end.
 *
 * @component
 * @category Views
 * @subcategory Admin View
 */
function UserDialog(props) {
  const classes = useStyles()
  const {t} = useTranslation("common", "admin")

  /**
   * A boolean flag which defines an action:
   *
   * | Value   | Action      |
   * | ------- | ----------- |
   * | _true_  | edit user   |
   * | _false_ | create user |
   *
   * Derived from the existence of prop [user]{@link UserDialog}
   */
  const editUser = Boolean(props.user)
  
  /**
   * Defines fields and its initial values of the dialog form.
   *
   * @type {object}
   * @prop {object} email
   * user's email (see state [email]{@link UserDialog~email} for details)
   * @prop {object} roles
   * user's roles (see state [roles]{@link UserDialog~roles} for details)
   * @prop {object} attributes
   * user's attributes (see state [attributes]{@link UserDialog~attributes} for details)
   */
  const initValues = {
    email: {
      value: '',
      error: null,
    },
    roles: {
      value: props.possibleRoles.reduce((result, role) => ({
        ...result,
        [role]: false,
      }), {}),
      error: t("admin:roles.error"),
    },
    attributes: {
      value: '',
      error: null,
    }
  }


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * Current values of the input field for user's email.
   *
   * @name email
   * @default [initValues.email]{@link UserDialog.initValues}
   * @prop {object} email - state
   * @prop {string} email.value
   * user's email
   * @prop {string} email.error
   * error message related to the email (empty string if it is no error)
   * @prop {function} setEmail - setter
   * @type {state}
   * @memberOf UserDialog
   * @inner
   */
  const [email, setEmail] = React.useState(initValues.email)
  
  /**
   * State<br/>
   * Current values of the input fields for user's roles.
   *
   * @name roles
   * @default [initValues.roles]{@link UserDialog.initValues}
   * @prop {object} roles - state
   * @prop {object} roles.value
   * user's roles as pairs '_role name_' - '_true_'/'_false_'
   * @prop {string} roles.error
   * error message related to the roles (empty string if it is no error)
   * @prop {function} setRoles - setter
   * @type {state}
   * @memberOf UserDialog
   * @inner
   */
  const [roles, setRoles] = React.useState(initValues.roles)
  
  /**
   * State<br/>
   * Current values of the input field for user's attributes.
   *
   * @name attributes
   * @default [initValues.attributes]{@link UserDialog.initValues}
   * @prop {object} attributes - state
   * @prop {string} attributes.value
   * user's attribute as a JSON string
   * @prop {string} attributes.error
   * error message related to the attributes (empty string if it is no error)
   * @prop {function} setAttributes - setter
   * @type {state}
   * @memberOf UserDialog
   * @inner
   */
  const [attributes, setAttributes] = React.useState(initValues.attributes)


  /**
   * If the current action is _edit user_ (see [editUser]{@link UserDialog~editUser}), then
   * sets the corresponding attributes of prop [user]{@link UserDialog} to states
   * [email]{@link UserDialog~email}, [roles]{@link UserDialog~roles},
   * [attributes]{@link UserDialog~attributes} when the component is mounted.
   *
   * @name useEffect
   * @function
   * @memberOf UserDialog
   * @inner
   * @arg {object} user
   * prop [user]{@link UserDialog}
   */
  React.useEffect(() => {
    if (!editUser) {
      return
    }

    // set user data
    setEmail({
      value: props.user.email,
      error: null,
    })
    setRoles({
      value: props.user.roles.reduce((result, role) => ({
        ...result,
        [role]: true,
      }), initValues.roles.value),
      error: null,
    })
    setAttributes({
      value: props.user.attributes,
      error: null,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user])


  /**
   * Event Handler<br/>
   * **_Event:_** change input value of _email_ field.<br/>
   * **_Implementation:_**<br/>
   * Sets the current input value and corresponding error message
   * to state [email]{@link UserDialog~email}.
   * The error message is derived from [validation result]{@link module:Utils.validateEmail})
   * of the current value: _null_ if validation is successful.
   */
  const handleUserChange = (event) => {
    const value = event.target.value
    const errorMsg = validateEmail(value) ? null : "Invalid email"
    setEmail({
      value: value,
      error: errorMsg,
    })
  }

  /**
   * Event Handler<br/>
   * **_Event:_** change value of _roles_ fields.<br/>
   * **_Implementation:_**<br/>
   * Sets the current values and corresponding error message
   * to state [roles]{@link UserDialog~roles}.
   * The error message is derived from validation if _at least one_ role is slected:
   * _null_ if validation is successful.
   */
  const handleRoleChange = (event) => {
    const newRoles = {
      ...roles.value,
      [event.target.name]: event.target.checked,
    }

    // check if at least one role checked
    const errorMsg = props.possibleRoles
      .filter((role) => newRoles[role]).length > 0 ? null : t("admin:roles.error")
    setRoles({
      value: {...newRoles},
      error: errorMsg,
    })
  }

  /**
   * Event Handler<br/>
   * **_Event:_** change input value of _attributes_ field.<br/>
   * **_Implementation:_**<br/>
   * Sets the current input value and corresponding error message
   * to state [attributes]{@link UserDialog~attributes}.
   * The error message is derived from [validation result]{@link module:Utils.validateJSONString})
   * of the current value: _null_ if validation is successful.
   */
  const handleAttributeChange = (event) => {
    const newValue = event.target.value
    const errorMsg = validateJSONString(newValue) ? null : t("admin:attributes.error")

    setAttributes({
      value: newValue,
      error: errorMsg,
    })
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click '_Confirm_' button.<br/>
   * **_Implementation:_**<br/>
   * pushes the current [action]{@link USerDialog.editUser} and valus of states
   * [email]{@link UserDialog~email}, [roles]{@link UserDialog~roles},
   * [attributes]{@link UserDialog~attributes} to prop [action]{@link UserDialog}
   * to run the _action_ on the back-end.
   */
  const handleConfirmClick = () => {
    // get action
    const action = editUser ? 'edit' : 'add'
    // build request body
    const payload = {
      email: email.value,
      roles: roles.value,
      attributes: attributes.value,
    }

    // clear fields
    setEmail(initValues.email)
    setRoles(initValues.roles)
    setAttributes(initValues.attributes)

    props.action(action, payload)
  }

  /**
   * Method<br/>
   * Validates the dialog form:
   * * state [email]{@link UserDialog~email} keeps a _value_ but does not keep an error message
   * * state [roles]{@link UserDialog~roles} does not keep an error message
   * * state [attributes]{@link UserDialog~attributes} does not keep an error message
   *
   * Triggers disability of '_Confirm_' button.
   */
  const validateForm = () => {
    return Boolean(email.value) && !Boolean(email.error) && !Boolean(roles.error) && !Boolean(attributes.error)
  }

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="user-dialog"
    >
      <DialogTitle
        id="user-dialog"
      >
        {editUser ? t("admin:user.edit") : t("admin:user.add")}
      </DialogTitle>
      <DialogContent>

        {/* User Email */}
        <FormControl
          className={classes.formItem}
          variant="outlined"
          size="small"
          fullWidth
          required
          disabled={editUser}
          error={Boolean(email.error)}
        >
          <InputLabel htmlFor="user-email">
            {t("admin:user.email")}
          </InputLabel>
          <OutlinedInput
            id="user-email"
            value={email.value}
            onChange={handleUserChange}
            label={t("admin:user.email")}
          />
          <FormHelperText>
            {email.error}
          </FormHelperText>
        </FormControl>
        
        {/* User Roles */}
        <FormControl
          className={classes.formItem}
          component="fieldset"
          error={Boolean(roles.error)}
        >
          <FormLabel
            component="legend"
          >
            {t("admin:roles")}
          </FormLabel>
          <FormGroup>
            {props.possibleRoles.map(role => (
              <FormControlLabel
                key={role}
                control={
                  <Checkbox
                    name={role}
                    checked={roles.value[role]}
                    onChange={handleRoleChange}
                  />}
                label={role}
              />
            ))}
          </FormGroup>
          <FormHelperText>
            {roles.error}
          </FormHelperText>
        </FormControl>

        {/* User Attributes */}
        <TextField
          id="user-attributes"
          label={t("admin:attributes")}
          multiline
          fullWidth
          variant="outlined"
          value={attributes.value}
          onChange={handleAttributeChange}
          size="small"
          error={Boolean(attributes.error)}
          helperText={Boolean(attributes.error) ? t("admin:attributes.error"): ""}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          color="primary"
          onClick={handleConfirmClick}
          disabled={!validateForm()}
        >
          {t("common:confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UserDialog.propTypes = {
  /**
   * If _true_ then the dialog is opened
   */
  open: PropTypes.bool.isRequired,
  /**
   * A [user instance]{@link AdminView~userInCompany}
   */
  user: PropTypes.object,
  /**
   * A list of the possible user's roles
   */
  possibleRoles: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback fired to run an _action_ (edit or create a user) in the back-end
   */
  action: PropTypes.func,
  /**
   * Callback fired when the dialog should be closed
   */
  onClose: PropTypes.func,
}

export default UserDialog
