import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { 
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core'
import { DataFieldFormControl } from './styled'
import { parseValue } from '../datafields'
import { typingTimeoutWithInputTrigger, formatNumberWithCommas } from '../utils'


/**
 * Renders data field of type _Zahl_ with predefined range of values.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Numeric
 */
function DataFieldNumberRange(props) {
  const {t} = useTranslation('common')

  /**
   * Field data extracted from _prop_ [data]{@link DataFieldNumberRange}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldNumberRange
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {array} inputRange
   * array of 3 elements, which define the range of possible values:
   * @prop {string} inputRange.0
   * should be "_range_" -- 
   * signals, that the current numeric field defines a range of the possible values
   * @prop {string} inputRange.1
   * the lowest value of the range
   * @prop {string} inputRange.2
   * the highest value of the range
   * @prop {bool} [isMandatory]
   * a boolean flag that shows if the data field is mandatory
   * @prop {string} [errorMessage]
   * an error message related to the field that comes from the back-end
   */
  const {id, data } = props
  

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that signals if the field holds an invalid value.
   *
   * @name error
   * @default false
   * @prop {bool} error - state
   * @prop {function} setError - setter
   * @type {state}
   * @memberOf DataFieldNumberRange
   * @inner
   */
  const [error, setError] = React.useState(false)

  /**
   * State<br/>
   * A helper text which appears under the input field.
   * It can show additional information or an error message.
   *
   * @name helperText
   * @default ''
   * @prop {string} helperText - state
   * @prop {function} setHelperText - setter
   * @type {state}
   * @memberOf DataFieldNumberRange
   * @inner
   */
  const [helperText, setHelperText] = React.useState('')

  /**
   * State<br/>
   * A _setTimeout_ callback fired when a user stops typing in the field input element
   *
   * @name typingTimeout
   * @default undefined
   * @prop {object} typingTimeout - state
   * @prop {function} setTypingTimeout - setter
   * @type {state}
   * @memberOf DataFieldNumberRange
   * @inner
   */
  const [typingTimeout, setTypingTimeout] = React.useState()

  /**
   * State<br/>
   * The current value of the data field.
   *
   * @name value
   * @default [value]{@link DataFieldNumberRange}
   * @prop {string} value - state
   * @prop {function} setValue - setter
   * @type {state}
   * @memberOf DataFieldNumberRange
   * @inner
   */
  const [value, setValue] = React.useState(props.value)
  
  /**
   * The lowest boundary of the value range.
   * Derived from _inputRange[1]_ of prop [data]{@link DataFieldNumberRange.data}
   */
  const min = Number(data.inputRange[1])
  /**
   * The highest boundary of the value range.
   * Derived from _inputRange[2]_ of prop [data]{@link DataFieldNumberRange.data}
   */
  const max = Number(data.inputRange[2])

  /**
   * Method<br/>
   * Validates if the input value is within the predefined range.
   *
   * @function
   * @arg {string} [valueToValidate=[value]{@link DataFieldNumberRange~value}]
   * numeric value to be validated
   * @returns {bool} 
   */
  const validateValue = (valueToValidate=value) => {
    return Boolean(valueToValidate) && valueToValidate >= min && valueToValidate <= max
  }


  /**
   * Updates sate [value]{@link DataFieldNumberRange~value} by
   * prop [value]{@link DataFieldNumberRange} every time it changes.
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldNumberRange
   * @inner
   * @variant 1
   * @arg {number} value
   * prop [value]{@link DataFieldNumberRange}
   */
  React.useEffect(() => {
    setValue(props.value)
  }, [props.value])


  /**
   * If prop [data.errorMessage]{@link DataFieldNumberRange.data} is defined
   * then sets its value to sate [helperText]{@link DataFieldNumberRange~helperText}
   * and _true_ to state [error]{@link DataFieldNumberRange~error}.
   * Otherwise, the values of the mentioned state are set to
   * a default helper message and result of
   * the value [validation]{@link DataFieldNumberRange~validateValue}
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldNumberRange
   * @inner
   * @variant 2
   * @arg {string} data.errorMessage
   * prop [data.errorMessage]{@link DataFieldNumberRange.data}
   * @arg {number} value
   * state [value]{@link DataFieldNumberRange~value}
   */
  React.useEffect(() => {
    // check if error comes from backend
    if (Boolean(data.errorMessage)) {
      setHelperText(data.errorMessage)
      setError(true)
      return
    }

    // set default range message
    setHelperText(t('common:value.range') + ': ' + formatNumberWithCommas(min) + '-' + formatNumberWithCommas(max))

    // check if value in range
    if ((Boolean(value) && value < min) || value > max) {
      setError(true)
      return
    } 

    // update error state
    if (!Boolean(value) || (value >= min && value <= max)) {
      setError(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, data.errorMessage])


  /**
   * Event Handler<br/>
   * **_Event:_** change input value.<br/>
   * **_Implementation:_**
   * * updates state [value]{@link DataFieldNumberRange~value} by the current input value
   * * updates state [typingTimeout]{@link DataFieldNumberRange~typingTimeout} using 
   * method [typingTimeoutWithInputTrigger]{@link module:Other.typingTimeoutWithInputTrigger}
   */
  const handleChange = (event) => {
    //const newValue = valueInRange(event.target.value)
    const newValue = event.target.value

    // update value
    setValue(newValue)

    // check if typing is NOT finished
    if (typingTimeout) {
      clearTimeout(typingTimeout)
    }

    // set timeout for typing finished
    setTypingTimeout(typingTimeoutWithInputTrigger(props, newValue, validateValue(newValue)))
  }

  return (
    <DataFieldFormControl
      variant="outlined"
      size="small"
      fullWidth
      required={data.isMandatory}
      error={error}
    >
      <InputLabel htmlFor={`${data.name}-${id}`}>
        {data.brief}
      </InputLabel>
      <OutlinedInput
        id={`${data.name}`}
        value={parseValue(value)}
        onChange={handleChange}
        label={data.brief}
        inputProps={{
          min: Number(data.inputRange[1]),
          max: Number(data.inputRange[2]),
          type: 'number',
        }}
      />
      <FormHelperText>
        {helperText}
      </FormHelperText>
    </DataFieldFormControl>
  )
}

DataFieldNumberRange.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldNumberRange.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the numeric field
   */
  value: PropTypes.string,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input value
   */
  onInputTrigger: PropTypes.func,
}

export default DataFieldNumberRange