import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BadgeImage from './badgeImg'

//styles
const useStyles = makeStyles((theme) => ({
  item: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
  },
}))


/**
 * This component renders a gamification badge.
 *
 * @component
 * @category Views
 * @subcategory Badge View
 */
function Badge(props) {
  const classes = useStyles()


  /**
   * Badge data extracted from _prop_ [badge]{@link Badge}
   * 
   * @name badge
   * @type {object}
   * @memberOf Badge
   * @prop {string} type
   * type of the badge (the possible badge types are defined on the back-end)
   * @prop {string} level
   * level of the badge (the possible badge levels are defined on the back-end)
   * @prop {string} next_level
   * the next level which is available for the badge
   * @prop {bool} isSeen
   * a boolean flag which shows if the user saw this badge before
   */
  /**
   * Badge type data extracted from _prop_ [type]{@link Badge}
   * 
   * @name type
   * @type {object}
   * @memberOf Badge
   * @prop {string} name
   * name of the badge type
   * @prop {string} title
   * title of the badge type
   * @prop {string} description
   * a brief description of the badge type
   */
  const {type, badge} = props

  
  /**
   * Event Handler<br/>
   * **_Event:_** click on the badge component.<br/>
   * **_Implementation:_**<br/>
   * Pushes props [badge]{@link Badge.badge} and [type]{@link Badge.type}
   * to prop [onClick]{@link Badge} to update the current badge within {@link BadgeView}
   */
  const handleBadgeClick = () => {
    // process only opened badges
    if (Boolean(badge)) {
      props.onClick({
        badge: badge,
        type: type,
      })
    }
  }

  return(
    <div
      className={classes.item}
      onClick={handleBadgeClick}
    >
      <BadgeImage {...badge} />
      <Typography
        className={classes.active}
        variant="subtitle1"
        component="div"
      >
        {type.title}
      </Typography>
    </div>
  )
}

Badge.propTypes = {
  /**
   * Instanse of the [current badge]{@link Badge.badge}
   */
  badge: PropTypes.object,
  /**
   * Instance of a [type of the current badge]{@link Badge.type}
   */
  type: PropTypes.object,
  /**
   * Callback fired when the badge component is clicked
   */
  onClick: PropTypes.func,
}

export default Badge