import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getBadgeSrc } from '../../api/gamification'


/**
 * This component renders the image of a gamification badge.
 *
 * @component
 * @category Views
 * @subcategory Badge View
 */
function BadgeImage(props) {

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * URL to the image object of the current badge within the front-end app
   *
   * @name badgeSrc
   * @default ''
   * @prop {string} badgeSrc - state
   * @prop {function} setBadgeSrc - setter
   * @type {state}
   * @memberOf BadgeImage
   * @inner
   */
  const [badgeSrc, setBadgeSrc] = React.useState('')


  /**
   * Method<br/>
   * Derives the value for _alt_ atribute of the badge image.
   * 
   * @returns {string}
   */
  const getImageAlt = () => {
    if (props.type)
      return `${props.level} ${props.type}`

    return "Disabled"
  }


  /**
   * Method<br/>
   * Derives the _width_ of the badge image.
   * 
   * @returns {string}
   */
  const getWidth = () => {
    if (props.overlay) 
      return "80%"

    return "50%"
  }


  /**
   * When the component is mounted, calls the back-end
   * [getBadgeSrc]{@link module:Gamification.getBadgeSrc}
   * to retrieve an URL of the badge image. Then sets the URL to 
   * state [badgeSrc]{@link BadgeImage~badgeSrc}, if the response is successful.
   *
   * @name useEffect
   * @function
   * @memberOf BadgeImage
   * @inner
   */
  React.useEffect(() => {
    const getBadgeRoute = () => {
      // green shadow
      if (props.isSeen === false && !Boolean(props.overlay)) {
        return "unseen"
      }
      
      // specific badge
      if (props.type) {
        return `${props.type.toLowerCase()}/${props.level.toLowerCase()}`
      }

      // gray shadow
      return "disabled"
    }

    // get badge src
    getBadgeSrc(props.user, getBadgeRoute()).then(src => {
      setBadgeSrc(src)
    }).catch(error => {
      console.log(error)
    })
    
  }, [props])

  return (
    <img
      src={badgeSrc}
      width={getWidth()}
      alt={getImageAlt()}
    />
  )
}

BadgeImage.propTypes = {
  /**
   * prop of the [bage instance]{@link Badge.badge} of the same name
   */
  type: PropTypes.string,
  /**
   * prop of the [bage instance]{@link Badge.badge} of the same name
   */
  level: PropTypes.string,
  /**
   * prop of the [bage instance]{@link Badge.badge} of the same name
   */
  isSeen: PropTypes.bool,
  /**
   * A boolean flag that shows if the bage image should be rendered in an overlay view
   */
  overlay: PropTypes.bool,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object.isRequired,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(BadgeImage)
