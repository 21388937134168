import React from 'react'
import PropTypes from 'prop-types'
import htmlParse from 'html-react-parser'
import {
  Switch,
  FormControlLabel
} from '@material-ui/core'

/**
 * Renders data field of type _Flag_ or _FlagWithOptions_ as a switch.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Boolean
 */
function DataFieldSwitch(props) {

  /**
   * Field data extracted from _prop_ [data]{@link DataFieldSwitch}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldSwitch
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {bool} [inputTriggers]
   * a boolean flag that shows if the parent instance should be updated
   * on the back-end if the current value is changed
   */
  const {data, value } = props

  /**
   * Event Handler<br/>
   * **_Event:_** change of the switch status<br/>
   * **_Implementation:_**
   * calls prop [_onChange_]{@link DataFieldSwitch} to update the value of the field.
   * Then calls prop [onInputTrigger]{@link DataFieldSwitch} to update the parent instance
   * if prop [data.inputTriggers]{@link DataFieldSwitch.data} is _true_.
   */
  const handleChange = (event) => {
    const newValue = {[data.name]: event.target.checked}
    props.onChange(newValue)
    
    // update on input trigger
    if (data.inputTriggers) {
      props.onInputTrigger(newValue)
    } 
  }

  return (
    <FormControlLabel
      control={
        <Switch
          id={`${data.name}`}
          checked={Boolean(value)}
          onChange={handleChange}
          color="primary"
        />
      }
      label={htmlParse(data.brief)}
    />
  )
}

DataFieldSwitch.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldSwitch.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the boolean flag
   */
  value: PropTypes.any,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input value
   */
  onInputTrigger: PropTypes.func,
}

export default DataFieldSwitch