import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { 
  Typography,
  LinearProgress,
} from '@material-ui/core'
import { Section } from '../policyDetails'
import { addPolicy } from '../../redux/actions'
import { getCustomerPolicies } from '../../api/policy'
import EnhancedTable from '../../datafields/enhancedTable'
import { backendDateFormat } from '../../dateFormat'

// styles
const useStyles = makeStyles((theme) => ({
  policies: {
    marginLeft: theme.spacing(2),
    color: theme.palette.error.light,
  },
}))

/**
 * This component fetches policies of the given customer and 
 * displays them as a [table]{@link EnhancedTable}.<br/>
 * The component renders a _waiting_ animation while fetching the policies.
 *
 * @component
 * @category Policy
 * @subcategory Customer
 */
function CustomerPolicies(props) {
  const {t} = useTranslation('policy')
  const classes = useStyles()

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A list of policies of the current customer. 
   *
   * @name isVisible
   * @default false
   * @prop {object[]} policies - state
   * @prop {function} setPolicies - setter
   * @type {state}
   * @memberOf CustomerPolicies
   * @inner
   */
  const [policies, setPolicies] = React.useState(null)

  /**
   * State<br/>
   * A boolean flag that signals if an error occurred while loading customer's policies.
   *
   * @name loadPoliciesError
   * @default false
   * @prop {boolean} loadPoliciesError - state
   * @prop {function} setLoadPoliciesError - setter
   * @type {state}
   * @memberOf CustomerPolicies
   * @inner
   */
  const [loadPoliciesError, setLoadPoliciesError] = React.useState(false)


  /**
   * Calls the back-end [getCustomerPolicies]{@link module:Policy.getCustomerPolicies}
   * for a list of customer's policies when the component is mounted.
   * If the response is _successful_ then sets the received policies to
   * state [policies]{@link CustomerPolicies~policies}.
   * Otherwise sets _true_ to state [loadPoliciesError]{@link CustomerPolicies~loadPoliciesError}.
   *
   * @name useEffect
   * @function
   * @memberOf CustomerPolicies
   * @inner
   */
  React.useEffect(() => {
    getCustomerPolicies(props.user, props.customer).then(data => {
      setPolicies(data.policies)
      setLoadPoliciesError(false)
    }).catch(error => {
      console.log(error)
      setLoadPoliciesError(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /**
   * Event Handler<br/>
   * **_Event:_** click a policy row.<br/>
   * **_Implementation:_**
   * pushes the number of a selected policy and the current date 
   * to prop [addPolicy]{@link NewPolicy} with request status _waiting_.
   */
  const hadnlePolicyClick = (value) => {

    // add policy
    props.addPolicy({
      request_state: "waiting",
      policy_number: Object.values(value)[0],
      effective_date: format(new Date(), backendDateFormat),
    })
  }

  //console.log(props)
  //console.log(policies)

  return(
    <Section>
      {policies ? (
        <EnhancedTable
          name="policies"
          title={t("policy:partner.policies")}
          data={policies}
          value={null}
          onChange={hadnlePolicyClick}
        />
      ) : (
        <React.Fragment>
          {loadPoliciesError ? (
            <Typography
              className={classes.policies}
              component="p"
              variant="body1"
            >
              {t("policy:policy.fail")}
            </Typography>
          ) : (
            <LinearProgress />
          )}
        </React.Fragment>
      )}
    </Section>
  )
}

CustomerPolicies.propTypes = {
  /**
   * ID number of the customer in the remote system
   */
  customer: PropTypes.string,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Redux action [addPolicy]{@link module:Actions.addPolicy}
   */
  addPolicy: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  addPolicy: addPolicy,
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerPolicies)
