/**
 * Back-End API Calls for Policy
 *
 * @category API Calls
 * @module Policy
 */


/**
 * GET `/api/policy/{policy_number}/{effective_date}`<br/>
 * Retrieve a policy instance by _policy number_ and _effective date_
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {object} policy
 * policy data
 * @arg {string} policy.policy_number
 * number of the policy
 * @arg {string} policy.effective_date
 * effective date of the policy
 * @returns {object}
 * the inctance of a policy
 */
export const fetchPolicy = async (user, policy) => {
  const response = await fetch(`/api/policy/${policy.policy_number}/${policy.effective_date}`, {
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * DELETE `/api/policy/delete/{policyId}`<br/>
 * Delete the policy instance by ID
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {string} policyId
 * ID of the policy to be deleted
 * @returns {object}
 * the status of the deletion
 */
export const deletePolicy = async (user, policyId) => {
  const response = await fetch(`/api/policy/delete/${policyId}`, {
    method: 'DELETE',
    headers: {'authorization': `Bearer ${user.accessToken}`},
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * POST `/api/policy/activity`<br/>
 * Execute an activity of the given policy
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {object} payload
 * request payload
 * @arg {string} payload.id
 * ID of the policy instance
 * @arg {object} payload.activity
 * the details of policy activity to be executed
 * @arg {string} payload.activity.name
 * the name of the activity to be executed
 * @arg {object} payload.activity.fields
 * a list of the data fields of the activity in form of _name_-_value_ pairs
 * @returns {object}
 * the instance of the policy after execution the activity 
 */
export const executeActivity = async (user, activity) => {
  const response = await fetch('/api/policy/activity', {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${user.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(activity),
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}

/**
 * POST `/api/policy/update`<br/>
 * Updates the values of the data fields of the given policy
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {object} payload
 * request payload
 * @arg {string} payload.id
 * ID of the policy instance
 * @arg {object} payload.activity
 * details of the current policy activity
 * @arg {string} payload.activity.name
 * the name of the current activity
 * @arg {object} payload.activity.fields
 * a list of the data fields of the current activity in form of _name_-_value_ pairs
 * @returns {object}
 * the updated instance of the policy
 */
export const updatePolicyFields = async (user, payload) => {
  const response = await fetch(`/api/policy/update`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${user.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
  const data = await response.json()

  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}


/**
 * POST `/api/search`<br/>
 * Search for partner's policies
 *
 * @async
 * @function
 * @arg {object} user
 * the current user's data
 * @arg {string} user.accessToken
 * the current user's access token
 * @arg {string} partner
 * ID number of the customer in the remote system
 * @returns {object[]}
 * a list of policies of the given customer 
 */
export const getCustomerPolicies = async (user, partner) => {
  const response = await fetch('/api/search', {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${user.accessToken}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      activity: 'partnerDetails',
      value: partner,
    }),
  })
  const data = await response.json()
  
  if (response.ok) {
    return data
  }

  throw new Error(data.error)
}