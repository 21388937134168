import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import htmlParse from 'html-react-parser'
import { makeStyles } from '@material-ui/core/styles'
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import { DataFieldFormControl } from './styled'

// styles
const useStyles = makeStyles((theme) => ({
  flagLabel: {
    color: theme.palette.text.primary,
    lineHeight: 1.43,
    letterSpacing: "0.01071em",
    fontSize: "0.875rem",
  },
}))

/**
 * Renders data field of type _RadioFlagWithOptions_ as a group
 * of two radio buttons.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Boolean
 */
function DataFieldRadio(props) {
  const {t} = useTranslation('common')
  const classes = useStyles()

  /**
   * Field data extracted from _prop_ [data]{@link DataFieldRadio}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldRadio
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {bool} [inputTriggers]
   * a boolean flag that shows if the parent instance should be updated
   * on the back-end if the current value is changed
   * @prop {bool} [isMandatory]
   * a boolean flag that shows if the data field is mandatory
   */
  const {data, value } = props


  /**
   * Method<br/>
   * Derives value of the radio group from value of the data field as follow:
   * | Field Value | Radio Group Value |
   * | ------------|-------------------|
   * | _true_      | "yes"             |
   * | _false_     | "no"              |
   * | any other   | _null_            |
   */
  const parseValue = () => {
    if (value === true)
      return "yes"

    if (value === false)
      return "no"

    return null
  }
  
  /**
   * Event Handler<br/>
   * **_Event:_** change of the radio buttons status<br/>
   * **_Implementation:_**
   * calls prop [_onChange_]{@link DataFieldRadio} to update the value of the field.
   * Then calls prop [onInputTrigger]{@link DataFieldRadio} to update the parent instance
   * if prop [data.inputTriggers]{@link DataFieldRadio.data} is _true_.
   */
  const handleChange = (event) => {
    const newValue = {[data.name]: event.target.value === "yes"}
    props.onChange(newValue)
    
    // update on input trigger
    if (data.inputTriggers) {
      props.onInputTrigger(newValue)
    } 
  }

  return (
    <DataFieldFormControl
      component="fieldset"
      required={data.isMandatory}
    >
      <div className={classes.flagLabel}>
        {htmlParse(data.brief)}
        <FormLabel component="legend"/>
      </div>
      <RadioGroup
        row
        value={parseValue()}
        onChange={handleChange}
      >
        <FormControlLabel
          value="yes"
          control={
            <Radio />
          }
          label={t("common:yes")}
        />
        <FormControlLabel
          value="no"
          control={
            <Radio />
          }
          label={t("common:no")}
        />
      </RadioGroup>
    </DataFieldFormControl>
  )
}

DataFieldRadio.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldRadio.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the boolean flag
   */
  value: PropTypes.any,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input value
   */
  onInputTrigger: PropTypes.func,
}

export default DataFieldRadio