import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'

/*
** Field with Tooltip
*/
/**
 * It is a wrapper component that adds a tooltip to a data field.
 *
 * @component
 * @category Data Fields
 */
function TooltipField(props) {

  return(
    <React.Fragment>
      {props.tooltip ? (
        <Tooltip
          title={props.tooltip}
          placement="top"
        >
          {props.children}
        </Tooltip>
      ) : (
        props.children
      )}
    </React.Fragment>
  )
}

TooltipField.propTypes = {
  /**
   * A tooltip message
   */
  tooltip: PropTypes.string,
  /**
   * Inner componnet
   */
  children: PropTypes.node,
}

export default TooltipField