import React from 'react'
import PropTypes from 'prop-types'
import { 
  FormControl,
  Select,
  MenuItem,
  Grid,
  OutlinedInput,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { languages } from '../strings/localization'


// styles
const useStyles = makeStyles({
  flagIcon: {
    minWidth: "56px",
  },
})

// bordless input field
const noBordersStyle = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      border: "none",
    },
    "&:hover $notchedOutline": {
      border: "none",
    },
    "&$focused $notchedOutline": {
      border: "none",
    }
  },
  focused: {},
  notchedOutline: {},
}))


/**
 * This ia a language selector component 
 * which changes the language of the interface of **PoLZy**.
 * 
 * @component
 * @category Auxiliary Components
 */
function LanguageSelector(props) {
  const classes = useStyles()
  const noBorders = noBordersStyle()
  const {i18n} = useTranslation()

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * The currently selected language option.
   *
   * @name value
   * @default ''
   * @prop {object} value - state
   * @prop {string} value.locale
   * two-char locale ('_eu_', '_de_' etc.)
   * @prop {string} value.label
   * Language label
   * @prop {string} value.isoCode
   * Corresponding country code according to ISO 3166-1 alpha-2
   * (used to derive a country flag)
   * @prop {function} setValue - setter
   * @type {state}
   * @memberOf LanguageSelector
   * @inner
   */
  const [value, setValue] = React.useState('')


  /**
   * When the component is mounted, sets the an option, which corresponds to
   * the current language in _i18n_ module, to state [value]{@link LanguageSelector~value} .
   *
   * @name useEffect
   * @function
   * @memberOf LanguageSelector
   * @inner
   */
  React.useEffect(() => {
    const currentOption = languages.filter(option => option.locale === i18n.language)
    setValue(currentOption.length ? currentOption[0] : '')
    // eslint-disable-next-line
  }, [])


  /**
   * Method<br/>
   * Derives unicode string which corresponds to a country flag
   * (ISO 3166-1 alpha-2 -- no support for IE 11)
   * 
   * @arg {string} isoCode
   * Country code according to ISO 3166-1 alpha-2
   * @returns {string}
   */
  const getFlag = (isoCode) => {
    if (isoCode)
      return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode

    return ''
  }


  /**
   * Inner Component<br/>
   * Renders a language option within a drop-down list.
   * 
   * @prop {object} innerProps
   * object of shape like state [value]{@link LanguageSelector~value}
   */
  const RenderOption = (innerProps) => {
    return (
      <Grid container>
        <Grid item className={classes.flagIcon}>{getFlag(innerProps.option.isoCode)}</Grid>
        {props.withLabel && 
          <Grid item>{innerProps.option.label}</Grid>
        }
      </Grid>
    )
  }


  /**
   * Event Handler<br/>
   * **_Event:_** select an option from the drop-down list.<br/>
   * **_Implementation:_**<br/>
   * sets the selected option to state [value]{@link LanguageSelector~value},
   * and the locale of the selected option to the current locale in _i18n_ module.
   */
  const handleChange = (event) => {
    setValue(event.target.value)
    i18n.changeLanguage(event.target.value.locale)
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
      size="small"
    >
      <Select
        id="language"
        value={value}
        onChange={handleChange}
        renderValue={option => (
          <RenderOption
            option={option}
            withLabel={props.withLabel}
          />
        )}
        input={
          <OutlinedInput
            classes={props.noBorders ? noBorders : {}}
          />
        }
      >
        {languages.map((option, index) => (
          <MenuItem key={index} value={option}>
            <RenderOption
              option={option}
              withLabel
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

LanguageSelector.propTypes = {
  /**
   * A boolean flag, which shows if the component should be borderless
   */
  noBorder: PropTypes.bool,
  /**
   * A boolean flag, which shows if the component should be rendered with the language labels 
   */
  withLabel: PropTypes.bool,
}

export default LanguageSelector