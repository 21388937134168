import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { 
  TextField,
  CircularProgress,
  Popper,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { addValues } from '../redux/actions'
import { getValueList } from '../api/general'

/*
** Drop-down Select with Filter
*/

// Styles
const useStyles = makeStyles((theme) => ({
  inputField: {
    paddingBottom: theme.spacing(2),
  },
}))


/**
 * A popper component of a content-depended width,
 * which is used to display the drop-down values.
 *
 * @memberOf DataFieldSelect
 */
const SelectPopper = (props) => {
  const styles = {
    popper: {
      width: "fit-content",
      minWidth: props.style.width,
    }
  }
  
  return (
    <Popper
      {...props}
      style={styles.popper}
      placement="bottom-start"
    />
  )
}

/*
function getAllMethods(object) {
    return Object.getOwnPropertyNames(object).filter(function(property) {
        return typeof object[property] == 'function';
    });
}
*/
/**
 * Renders data field of type _Text_ with predefined values as a drop-down list.
 *
 * @component
 * @category Data Fields
 * @subcategory Input - Text
 */
function DataFieldSelect(props) {
  const classes = useStyles()
  const {t} = useTranslation('common')


  /**
   * Field data extracted from _prop_ [data]{@link DataFieldSelect}
   *
   * @name data
   * @type {object}
   * @memberOf DataFieldSelect
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {array} inputRange
   * a list of the predefined values that populates the drop-down list.
   * Alternatively, if the 1st value is '_async_', then the 2nd value should be the name of a value list
   * (see [valueLists]{@link module:State~valueLists} for details), which populates the drop-down list.
   * @prop {bool} [inputTriggers]
   * a boolean flag that shows if the parent instance should be updated
   * on the back-end using the current input value
   * @prop {bool} [isMandatory]
   * a boolean flag that shows if the data field is mandatory
   * @prop {string} [errorMessage]
   * error message of the field retrieved from the back-end
   */
  const {id, data, value } = props


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * List of the drop-down values.
   *
   * @name options
   * @default []
   * @prop {string[]} options - state
   * @prop {function} setOptions - setter
   * @type {state}
   * @memberOf DataFieldSelect
   * @inner
   */
  const [options, setOptions] = React.useState([])

  /**
   * State<br/>
   * A boolean flag that signals, if the drop-down values are currently loaded from the back-end.
   *
   * @name loading
   * @default []
   * @prop {bool} loading - state
   * @prop {function} setLoading - setter
   * @type {state}
   * @memberOf DataFieldSelect
   * @inner
   */
  const [loading, setLoading] = React.useState(false)
  /**
   * State<br/>
   * Defines if the current input value is invalid.
   *
   * @name error
   * @prop {object} error - state
   * @prop {bool} error.show=false
   * if _true_ then the error message should be shown
   * @prop {string} error.message=''
   * an error message
   * @prop {function} setError - setter
   * @type {state}
   * @memberOf DataFieldSelect
   * @inner
   */
  const [error, setError] = React.useState({
    show: false,
    message: '',
  })


  /**
   * Populates the drop-down list. The first element of prop [data.inputRange]{@link DataFieldSelect.data}
   * defines the implementation scenario:
   *
   * * [data.inputRange]{@link DataFieldSelect.data}[0] !== '_async_'<br/>
   * sets prop [data.inputRange]{@link DataFieldSelect.data}
   * to state [options]{@link DataFieldSelect~options}
   * * [data.inputRange]{@link DataFieldSelect.data}[0] === '_async_'<br/>
   * reads the name of a value list from the second element of [data.inputRange]{@link DataFieldSelect.data}
   * and sets the corresponding value list from _redux_ state [valueLists]{@link module:State~valueLists}
   * to state [options]{@link DataFieldSelect~options}. If [valueLists]{@link module:State~valueLists}
   * does not keep a value list of the specified name, then fetches it from the back-end first.
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldSelect
   * @inner
   * @variant 1
   * @arg {string[]} data.inputRange
   * prop [data.inputRange]{@link DataFieldSelect.data}
   */
  React.useEffect(() => {
    // inputRange contains options
    if (data.inputRange.length === 0 || data.inputRange[0] !== "async") {
      setOptions(data.inputRange)
      return
    }

    // options should be fetched from backend
    const valueListName = data.inputRange[1]

    // options were already fetched
    if (props.valueLists[valueListName]) {
      setOptions(props.valueLists[valueListName])
      return
    }

    // call backend for options
    setLoading(true)
    setOptions([])
    getValueList(
      props.user,
      {
        'instanceId': id,
        'valueListName': valueListName,
      },
    ).then(data => {
      setOptions(data)
      props.addValues({
        name: valueListName,
        values: data,
      })
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.inputRange])

  /**
   * Sets error (show status _true_ and error message)
   * to state [error]{@link DataFieldTextSlider~error}, if either of the following conditions met:
   * * prop [data.errorMessage]{@link DataFIeldSelect.data} holds an error message from the back-end
   * * the curent field value is not within the list of possible values
   * defined by prop [options]{@link DataFieldSelect~options}
   * * the data field is mandatory ([data.isMandatory]{@link DataFieldSelect.data} === _true_)
   * but the value is empty
   *
   * @name useEffect
   * @function
   * @memberOf DataFieldSelect
   * @inner
   * @variant 2
   * @arg {string} data.errorMessage
   * prop [data.errorMessage]{@link DataFieldSelect.data}
   * @arg {string[]} options
   * state [options]{@link DataFieldSelect~options}
   * @arg {string} value
   * prop [value]{@link DataFieldSelect}
   */
  React.useEffect(() => {
    if (data.errorMessage) {
      setError({
        show: true,
        message: data.errorMessage,
      })
      return
    }

    if (data.isMandatory && options.length > 0 && !options.includes(value)) {
      setError({
        show: true,
        message: t('common:wrong.value'),
      })
      return
    }

    setError({
      show: false,
      message: '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value, data.errorMessage])

  /**
   * Event Handler<br/>
   * **_Event:_** change input value<br/>
   * **_Implementation:_**
   * If prop [data.inputTriggers]{@link DataFieldSelect.data} is _true_
   * then calls prop [onInputTrigger]{@link DataFieldSelect} to update the parent instance on the back-end.
   * Otherwise, calls prop [onChange]{@link DataFieldSelect} to update the value of the field.
   */
  const handleChange = (event, newValue) => {
    const updateValue = {[data.name]: newValue}
    
    // update on input trigger
    if (data.inputTriggers) {
      props.onInputTrigger(updateValue)
    } else {
      // update antrag value
      props.onChange(updateValue)
    }
  }

  return (
    <Autocomplete
      classes={props.hideHelper ? {} : {root: classes.inputField}}
      id={`${data.name}`}
      value={Boolean(value) ? value : null}
      onChange={handleChange}
      options={options}
      PopperComponent={SelectPopper}
      fullWidth
      size="small"
      renderInput={(params) => 
        <TextField {...params}
          label={data.brief}
          variant="outlined"
          required={data.isMandatory}
          error={error.show}
          helperText={error.message}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading &&
                  <CircularProgress color="inherit" size={20} />
                }
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      }
    />
  )
}


DataFieldSelect.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string.isRequired,
  /**
   * Field data (see [data]{@link DataFieldTextSlider.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the data field
   */
  value: PropTypes.string,
  /**
   * _Redux_ state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * _Redux_ state [valueLists]{@link module:State~valueLists}
   */
  valueLists: PropTypes.object,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * Callback fired to update the parent instance on the back-end using the current input value
   */
  onInputTrigger: PropTypes.func,
  /**
   * _Redux_ action [addValues]{@link module:Actions.addValues}
   */
  addValues: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
  valueLists: state.valueLists,
})

const mapDispatchToProps = {
  addValues: addValues,
}

export default connect(mapStateToProps, mapDispatchToProps)(DataFieldSelect)