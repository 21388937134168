/**
 * Data Field Utils
 *
 * @category Data Fields
 * @module Datafield
 */


/**
 * Replace empty value (_null_, _undefined_) by emty string ""
 * 
 * @function
 * @arg {any} value
 * @returns {any}
 * Returns _value_ if it is not empty<br/>
 * Returns "" if _value_ is empty 
 */
export const parseValue = (value) => {
  if (value) return value

  return ""
}
