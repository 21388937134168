import htmlParse from 'html-react-parser'

/**
 * Utilities that parse strings
 *
 * @category Utils
 * @module Parse
 */


/**
 * Parses html-text making links open in a new tab. 
 * For toasts (notifications) the links are also styled (by setting _class_)
 *  
 * @function
 * @arg {string} htmlTest
 * the html-tesxt to be parsed
 * @returns {array}
 */
export const parseHtmlTextWithLink = (htmlText, toast=false) => {
  const formatLink = (textObject) => {
    // add target = "_blank" prop
    if (textObject.type === "a") {
      const formatted = {
        ...textObject,
        props: {
          ...textObject.props,
          target: "_blank",
        },
      }

      // format toast link
      if (toast) {
        formatted.props.className = "toast-link"
      }

      return formatted
    }

    // return intact object if it is not a link
    return textObject
  }

  // parse input text
  const result = htmlParse(htmlText)

  // multiple objects parsed
  if (Array.isArray(result)) {
    return result.map(chunk => formatLink(chunk))
  }

  // single object parsed
  return formatLink(result)
}


/**
 * Converts a string value of a data field from the back-end
 * to value of the corresponding type 
 *
 * @function
 * @arg {object} field
 * data field object
 * @arg {string} field.name
 * the name of the data field
 * @arg {string} field.value
 * the value of the data field
 * @arg {string} field.fieldDataType
 * the type of the data field value
 * @arg {array} field.relatedFields
 * sub-fields of the data field
 * @returns {object}
 * object with name-value pairs
 */
export const getFieldValue = (field) => {

  const parseBoolValue = (value) => {
    switch (value) {
      case 'True':
        return true
      case 'False':
        return false
      default:
        return null
    }
  }

  // Boolean with related fields 
  if (field.fieldDataType.includes("WithOptions") && field.relatedFields) {
    return {
      [field.name]: parseBoolValue(field.value),
      ...field.relatedFields.reduce((result, subField) => ({
        ...result,
        ...getFieldValue(subField),
      }), {})
    }
  }

  // Boolean
  if (field.fieldDataType.includes("Flag"))  {
    return {[field.name]: parseBoolValue(field.value)}
  }

  // empty values
  if (field.value === undefined || field.value === "None") {
    return {[field.name]: ""}
  }

  // other
  return {[field.name]: field.value}
}


/**
 * Parses JSON string to object
 * 
 * @function
 * @arg {string} string
 * the string to be parsed
 * @returns {object}
 */
export const parseJSONString = (string) => {
  // no parse needed
  if (typeof(string) === 'object') {
    return string
  }

  // empty values
  if (string === "None" || !Boolean(string)) {
    return null
  }

  try {
    return JSON.parse(string)
  } catch(error) {
    console.log(error)
    return null
  }
}
