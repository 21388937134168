import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { CardDisabled, CardTop, CardMiddle } from '../styles/cards'
import CardTitle from '../components/cardTitle'
import { updatePolicy } from '../redux/actions'
import { fetchPolicy } from '../api/policy'


/**
 * Waiting Animation Element
 */
const WaitingProgress = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  }
}))(LinearProgress)

/**
 * This component renders a policy card with the request status _waiting_ while
 * requesting the policy data from the back-end.
 * @see [MapPolicyCard]{@link PolicyView.MapPolicyCard} for possible request status of a policy instance
 *
 * @component
 * @category Policy
 */
function DisabledPolicy(props) {
  const {index, policy} = props

  /**
   * Calls the back-end [fetchPolicy]{@link module:Policy.fetchPolicy} for a policy instance,
   * when the component is mounted.
   * Then pushes to prop [updatePolicy]{@link DisabledPolicy}:
   * * the received policy instance if the response is _OK_
   * * the current policy instance with state _failed_ if the response is _error_
   *
   * @name useEffect
   * @function
   * @memberOf DisabledPolicy
   * @inner
   */
  React.useEffect(() => {
    fetchPolicy(props.user, policy).then(data => {
      props.updatePolicy(
        index,
        {
          request_state: "ok",
          ...data,
        }
      )
    }).catch(error => {
      console.log(error)
      props.updatePolicy(
        index,
        {
          ...policy,
          request_state: "failed",
          error: error.message,
        }
      )
    })  
  })

  return(
    <CardDisabled>
      <CardTop
        title={
          <CardTitle
            type="policy"
            name={policy.policy_number}
          />
        }
        subheader={policy.effective_date}
      />
      <CardMiddle>
        <WaitingProgress />
      </CardMiddle>
    </CardDisabled>
  )
}

DisabledPolicy.propTypes = {
  /**
   * The index of the policy instance in the [redux store]{@link module:State~policies}
   */
  index: PropTypes.number,
  /**
   * The policy instance
   */
  policy: PropTypes.object,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Redux action [updatePolicy]{@link module:Actions.updatePolicy}
   */
  updatePolicy: PropTypes.func,
}


// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  updatePolicy: updatePolicy,
}

export default connect(mapStateToProps, mapDispatchToProps)(DisabledPolicy)