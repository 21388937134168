/**
 * Utilities that does not match any other category
 *
 * @category Utils
 * @module Other
 */


/**
 * Holds URI of API host<br/>
 * The API host is derived from _env_ `REACT_APP_API_HOST`
 * 
 * @const {string}
 * @default "http://localhost:5000/"
 */
export const apiHost = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : 'http://localhost:5000/'


/**
 * Implements typing timeout to decrease the back-end requests.
 * Utilized by input fields which requires fetching data from the back-end
 * when input value changes.
 *
 * @function
 * @arg {object} pops
 * props of the input field
 * @arg {any} value
 * actual value of the field
 * @arg {bool} [isValid=true]
 * boolean flag which shows is the input field is _valid_
 * @returns {object}
 */
export const typingTimeoutWithInputTrigger = (props, value, isValid=true) => {
  // define interval in ms that signals about stop of typing
  const typingDuration = 500

  return(
    setTimeout(() => {
      // check for input trigger and valid value
      if (props.onInputTrigger && props.data.inputTriggers && isValid) {
        // input trigger
        props.onInputTrigger({[props.data.name]: value})
      } else if (props.onChange) {
        // update antrag value
        props.onChange({[props.data.name]: value})
      }
    }, typingDuration)
  )
}


// Exports
export {
  validateEmail,
  validateJSONString,
  validateSearchString,
  validateIBAN,
} from "./validate"

export {
  formatNumberWithCommas,
  formatNumberWithSuffix,
  formatRankWithSuffix,
  capitalizeFirstChar,
} from "./format"

export {
  parseHtmlTextWithLink,
  getFieldValue,
  parseJSONString,
} from "./parse"

//module.exports = require("./validate")
