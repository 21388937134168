import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import SearchDropDown from './searchDropDown'

/**
 * Renders a dialog as a modal window to find a partner instance on the back-end.
 *
 * @component
 * @category Data Fields
 * @subcategory Search Field
 */
function FindDialog(props) {
  const {t} = useTranslation("common", "antrag")

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={`find-${props.data.endpoint}-${props.id}`}
    >
      <DialogTitle id={`find-${props.data.endpoint}-${props.id}`}>
        {t("antrag:partner.find")}
      </DialogTitle>
      <DialogContent>
        <SearchDropDown
          {...props}
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {t("common:close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FindDialog.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string,
  /**
   * Data of the related search field
   */
  data: PropTypes.object,
  /**
   * A boolean flag that signals if the dialog is open
   */
  open: PropTypes.bool.isRequired,
  /**
   * Callback fired when the dialog should be closed
   */
  onClose: PropTypes.func.isRequired,
}

export default FindDialog