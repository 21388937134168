import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { TextField, CircularProgress } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { searchPortal } from '../../api/antrag'
import { validateSearchString } from '../../utils'
import { updateAddressList } from '../../redux/actions'


// Styles
const useStyles = makeStyles((theme) => ({
  inputField: {
    paddingBottom: theme.spacing(2),
  },
}))


/**
 * Renders a text input field which accepts a search string 
 * and provides a drops-down list fetched from the back-end.
 *
 * @component
 * @category Data Fields
 * @subcategory Search Field
 */
function SearchDropDown(props) {
  const classes = useStyles()

  /**
   * Field data extracted from _prop_ [data]{@link SearchDropDown}
   *
   * @name data
   * @type {object}
   * @memberOf SearchDropDown
   * @prop {string} name
   * the name of the data field
   * @prop {string} brief
   * a short description of the data field
   * @prop {string} endpoint
   * name of a back-end endpoint to search for the possible values
   * @prop {bool} [inputTriggers]
   * a boolean flag that shows if the parent instance should be updated
   * on the back-end using the current input value
   * @prop {bool} [isMandatory]
   * a boolean flag that shows if the data field is mandatory
   */
  const {id, data} = props


  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that shows if the possible options are currently being loaded from the back-end.
   *
   * @name loading
   * @default false
   * @prop {bool} loading - state
   * @prop {function} setLoading - setter
   * @type {state}
   * @memberOf SearchDropDown
   * @inner
   */
  const [loading, setLoading] = React.useState(false)

  /**
   * State<br/>
   * The list of options that match the currently entered search string.
   * Initially set to an empty array or an [address list]{@link module:State~addressList}
   * associated with the parent instance. 
   *
   * @name options
   * @prop {bool} options - state
   * @prop {function} setOptions - setter
   * @type {state}
   * @memberOf SearchDropDown
   * @inner
   */
  const [options, setOptions] = React.useState(
    data.endpoint === "address" && props.addressList[id] ? 
    Object.values(props.addressList[id]) : 
    []
  )

  /**
   * Event Handler<br/>
   * **_Event:_** change input value<br/>
   * **_Implementation:_**
   * Calls [searchPortal]{@link module:Antrag.searchPortal} to retrieve options
   * from the back-end which match the current search string then
   * set them to state [options]{@link SearchDropDown~options}.
   */
  const handleInputChange = (event, newValue, reason) => {

    if (reason === "clear") {
      //props.saveInstance('')
      return
    }

    if (reason !== "input" || !validateSearchString(newValue)) {
      return
    }

    setLoading(true)

    // call backend
    searchPortal(
      props.user,
      props.id,
      data.endpoint,
      newValue,
    ).then(data => {
      setOptions(data)
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })

  }

  /**
   * Event Handler<br/>
   * **_Event:_** select an option from the drop-down list<br/>
   * **_Implementation:_**
   * If prop [data.inputTriggers]{@link SearchDropDown.data} is _true_
   * then calls prop [onInputTrigger]{@link SearchDropDown} to update the parent instance on the back-end.
   * Otherwise, calls prop [onChange]{@link SearchDropDown} to update the value of the field.
   * Then checks prop [disableSave]{@link SearchDropDown}: if it is _false_ -- saves the currently
   * selected address (if it is an address search field) by calling
   * prop [updateAddress]{@link SearchDropDown}.
   * 
   */
  const handleValueSelect = (event, newValues) => {
    if (newValues === null){
      return
    }

    // update on input trigger
    if (data.inputTriggers) {
      props.onInputTrigger({[data.name]: newValues})
    } else {
      // update antrag value
      props.onChange({[data.name]: newValues})
    }

    if (props.disableSave) {
      return
    }

    if (data.endpoint === "address") {
      props.updateAddress(
        id,
        {
          [data.name]: newValues,
        },
      )
    }

  }

  //console.log(`Search Field ${data.name}`)
  //console.log(props)

  return (
    <Autocomplete
      classes={{root: classes.inputField}}
      id={`${data.name}-${id}`}
      fullWidth
      size="small"
      getOptionSelected={(option, value) => option.label === value.label}
      getOptionLabel={(option) => option.label}
      filterOptions={(options) => options}
      onInputChange={handleInputChange}
      onChange={handleValueSelect}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={data.brief}
          variant="outlined"
          required={data.isMandatory}
          autoFocus={props.autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

SearchDropDown.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string,
  /**
   * Data of the related search field
   */
  data: PropTypes.object,
  /**
   * A boolean flag that shows if the current address value should be saved within the parent instance
   */
  disableSave: PropTypes.bool,
  /**
   * _Redux_ state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * _Redux_ state [addressList]{@link module:State~addressList}
   */
  addressList: PropTypes.object,
  /**
   * Callback fired to update the parent instance on the back-end using the current value
   */
  onInputTrigger: PropTypes.func,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
  /**
   * _Redux_ action [updateAddressList]{@link module:Actions.updateAddressList}
   */
  updateAddress: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
  addressList: state.addressList,
})

const mapDispatchToProps = {
  updateAddress: updateAddressList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchDropDown)