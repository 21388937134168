import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { 
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Link,
  Collapse,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'
import ExpandButton from '../components/expandButton'

/**
 * Collection of components used within {@link ActivePolicy}
 *
 * @category Policy
 * @module AuxiliaryComponents
 */

/**
 * Material-UI [TableCell]{@link https://material-ui.com/api/table-cell/} component
 * styled with the background color to highlight **active** status of a policy.  
 */
const StatusActive = withStyles((theme) => ({
  head: {
    color: theme.palette.success.main,
  },
}))(TableCell)

/**
 * Material-UI [TableCell]{@link https://material-ui.com/api/table-cell/} component
 * styled with the background color to highlight **suspended** status of a policy.  
 */
const StatusSuspended = withStyles((theme) => ({
  head: {
    color: theme.palette.warning.main,
  },
}))(TableCell)

/**
 * Material-UI [TableCell]{@link https://material-ui.com/api/table-cell/} component
 * styled with the background color to highlight **canceled** status of a policy.  
 */
const StatusCanceled = withStyles((theme) => ({
  head: {
    color: theme.palette.secondary.main,
  },
}))(TableCell)

// Styles for policy section container
export const Section = withStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}))(Paper)


/**
 * Material-UI [Tooltip]{@link https://material-ui.com/api/tooltip/} component
 * styled to be formatted with HTML syntax.  
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

// Style for policy section title
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.common.black,
    paddingLeft: theme.spacing(2),
  },

  divFlex: {
    display: "flex",
  }
}));


/**
 * This component renders values substituting boolean values by its graphical representations.
 *
 * @prop {any} value
 * a value to be rendered
 */
function GetValue(props) {
  const {value} = props

  if (typeof value === "boolean"){
    return(
      <React.Fragment>
        {value ? (
          <DoneIcon style={{ color: "green" }} />
        ) : (
          <CloseIcon color="secondary" />
        )}
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {value}
    </React.Fragment>
  )
}

/**
 * Renders a policy attribute as a table row, highlighting it on mouse over.
 *
 * @prop {string} title
 * the attribute title
 * @prop {string} value
 * the attribute value
 */
export function MakeRow(props) {
  const {title, value} = props

  return(
    <React.Fragment>
      <TableRow hover>
        <TableCell>{title}</TableCell>
        <TableCell>{value}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

/**
 * Renders a policy **clause** as a table row which provides:
 * * an HTML-formatted description of the clause on mouse over
 * * a link for the clause's details
 *
 * @prop {object} clause
 * the details of a clause
 * @prop {string} clause.name
 * the name of a clause
 * @prop {string} clause.number
 * the number of a clause
 * @prop {string} clause.description
 * the description of the clause (supports HTML format)
 * @prop {string} clause.link
 * URL for the clause's details
 */
function MakeClauseRow(props) {
  const {clause} = props

  return(
    <React.Fragment>
      <HtmlTooltip 
        placement="left"
        arrow
        interactive
        title={
          <React.Fragment>
            <h4>{clause.description}</h4>
          </React.Fragment>
        } 
      >
      <TableRow hover>
        <TableCell>{clause.name}</TableCell>
        <TableCell>
          <Link
            href={clause.link}
            target="_blank"
          >
            {clause.number}
          </Link>
        </TableCell>
      </TableRow>
      </HtmlTooltip>
    </React.Fragment>
  )
}

/**
 * Renders a title of a policy section.
 *
 * @prop {string} title
 * a title to be rendered
 */
export function Title(props) {
  const classes = useStyles()

  return (
    <Typography 
      className={classes.title}
      component="h2"
      variant="h6"
      color="primary"
      
    >
      {props.title}
    </Typography>
  )
}

/**
 * Renders a policy status as colored text.
 *
 * @prop {string} status
 * a status to be rendered
 */
function PolicyStatus(props) {
  const {status} = props

  switch(status) {
    case 'active':
      return <StatusActive>{status}</StatusActive>
    case 'canceled':
      return <StatusCanceled>{status}</StatusCanceled>
    case 'suspended':
      return <StatusSuspended>{status}</StatusSuspended>
    default:
      return <TableCell>{status}</TableCell>
  }
}

/**
 * Renders the general data of a policy.
 *
 * @prop {object} data
 * the general data of a policy
 * @prop {string} data.status
 * the status of a policy
 * @prop {string} data.remote_system
 * the name of a remote system of a policy
 * @prop {object} data.attributes
 * the attributes of a policy in form of
 * ```json
 * <attributeName>: <attributeValue>
 * ```
 */
function PolicyMain(props) {
  const {data} = props
  const {t} = useTranslation('policy')

  return(
    <React.Fragment>
      <Section>
        <Table size="small">
          <TableHead>
            <TableRow hover>
              <TableCell>{t("status")}</TableCell>
              <PolicyStatus status={data.status} />
            </TableRow>
          </TableHead>
          <TableBody>
            <MakeRow title="Remote System" value={data.remote_system} />
            {Object.keys(data.attributes).map((attr) => (
              <MakeRow key={attr} title={attr} value={data.attributes[attr]} />
            ))}
          </TableBody>
        </Table>
      </Section>
    </React.Fragment>
  )
}

/**
 * Renders the **product line** section of a policy.
 *
 * @prop {object} data
 * the details of the product line of a policy
 * @prop {string} data.name
 * the name of a product line
 * @prop {object} data.attributes
 * the attributes of the product line in form of
 * ```json
 * <attributeName>: <attributeValue>
 * ```
 */
function ProductLine(props) {
  const {data} = props
  const {t} = useTranslation('policy')

  return(
    <React.Fragment>
      <Section>
        <Title title={t("product.line") + ": " + data.name} />
        <Table size="small">
          <TableBody>
            {Object.keys(data.attributes).map((attr) => (
              <MakeRow key={attr} title={attr} value={<GetValue value={data.attributes[attr]} />} />
            ))}
          </TableBody>
        </Table>
      </Section>
    </React.Fragment>
  )
}

/**
 * Renders the **premium payer** and the **insured object** sections of a policy.
 *
 * @prop {string} title
 * the title of a section
 * @prop {object} data
 * the attributes of the section in form of
 * ```json
 * <attributeName>: <attributeValue>
 * ```
 */
export function GenericSection(props) {
  const {title, data} = props

  return(
    <React.Fragment>
      <Section>
        <Title title={title} />
        <Table size="small">
          <TableBody>
            {Object.keys(data).map((attr) => (
              <MakeRow key={attr} title={attr} value={data[attr]} />
            ))}
          </TableBody>
        </Table>
      </Section>
    </React.Fragment>
  )
}

/**
 * Renders the **clauses** section of a policy as a expandable section.
 * Initially the section appears collapsed.
 *
 * @prop {object[]} data
 * a list of the clauses of a policy
 * (see [details of a clause object]{@link module:AuxiliaryComponents~MakeClauseRow})
 * 
 */
function Clauses(props) {
  // renders clauses
  const {data} = props
  const {t} = useTranslation('policy')
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  //console.log("Clauses")
  //console.log(data)

  return(
    <React.Fragment>
      <Section>
        <div className={classes.divFlex}>
          <Title title={t("clauses")} />
          <ExpandButton
            expanded={open}
            onClick={() => setOpen(!open)}
          />
        </div>
        <Collapse in={open} timeout="auto">
          <Table size="small">
            <TableBody>
              {data.map((clause, index) => (
                <MakeClauseRow
                  key={`${clause.name}-${index}`} 
                  clause={clause} 
                />
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </Section>
    </React.Fragment>
  )
}


/**
 * This component defines the layout for displaying the details of a policy.
 *
 * @prop {object} policy
 * policy details as described in [ActivePolicy]{@link ActivePolicy.policy}
 */
export function PolicyDetails(props) {
  const {policy} = props
  const {t} = useTranslation('policy')

  //console.log(policy)

  return(
    <React.Fragment>
      <Grid container direction="row">
        <Grid container direction="column" item xs={12} md={4}>
          <PolicyMain data={policy} />
          <ProductLine data={policy.product_line} />
          <GenericSection title={t('premium.payer')} data={policy.premium_payer} />
        </Grid>
        <Grid container direction="column" item xs={12} md={4}>
          <GenericSection title={t('insured.object')} data={policy.insured_object} />
        </Grid>
        <Grid container direction="column" item xs={12} md={4}>
          <Clauses data={policy.clauses} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

