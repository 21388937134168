import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'


/**
 * This component renders a title for [policy cards]{@link PolicyView.MapPolicyCard}
 * and [product offer cards]{@link AntragView.MapAntragCard}.
 *
 * @component
 * @category Auxiliary Components
 */
function CardTitle(props) {
  const {t} = useTranslation('policy', 'antrag')

  /**
   * Method<br/>
   * Derives text of the title based on the type of the card. 
   */
  const getTitle = () => {
    // policy title
    if (props.type === 'policy') {
      return `${t('policy:policy')} ${props.name}`
    }

    // antrag title
    if (props.type === 'antrag') {
      return `${t('antrag:fast.offer')}: ${props.name}`
    }

    // default
    return props.name
  }

  return(
    <Typography
      component="p"
      variant="h5"
    >
      {getTitle()}
    </Typography>
  )

}

CardTitle.propTypes = {
  /**
   * Type of the card
   */
  type: PropTypes.string,
  /**
   * Name of the card
   */
  name: PropTypes.string.isRequired,
}

export default CardTitle