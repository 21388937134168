/**
 * Utilities that format strings
 *
 * @category Utils
 * @module Format
 */


/**
 * Displays number with commas as thousands separators
 * 
 * @function
 * @arg {number} number
 * the number to be formatted
 * @returns {string}
 */
export const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

/**
 * Displays number with a suffix:
 * * _k_ -- for thousands
 * * _M_ -- for millions
 * 
 * @function
 * @arg {number} number
 * the number to be formatted
 * @returns {string}
 */
export const formatNumberWithSuffix = (number) => {
  if (number < 1000) 
    return number.toString()

  if (number < 10000)
    return (number/1000).toFixed(1) + 'k'

  if (number < 1000000)
    return Math.round(number/1000) + 'k'

  if (number < 10000000)
    return (number/1000000).toFixed(1) + 'M'

  return Math.round(number/1000000) + 'M'
}


/**
 * Displays number as ordinal number with suffix
 *
 * @function
 * @arg {number} number
 * the number to be formatted
 * @returns {string}
 */
export const formatRankWithSuffix = (number) => {
  const d = number % 100
  if (d > 3 && d < 21) return "th"
  switch (number % 10) {
    case 1: return "st"
    case 2: return "nd"
    case 3: return "rd"
    default: return "th"
  }
}


/**
 * Display string with capitalized first character
 *
 * @function
 * @arg {string} string
 * the string to be formatted
 * @returns {string}
 */
export const capitalizeFirstChar = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
