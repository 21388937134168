import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Tooltip, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslation } from 'react-i18next'

// Styles
const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },

}))


/**
 * This component renders an _expand_ button which reflects
 * the expanded or collapsed status of the parent.
 * 
 * @component
 * @category Auxiliary Components
 */
function MoreButton(props) {
  const classes = useStyles()
  const {t} = useTranslation('common')

  return(
    <Tooltip title={props.expanded ? (t("common:collapse")) : (t("common:expand"))}>
      <IconButton
        className={clsx(classes.expand, {
          [classes.expandOpen]: props.expanded,
        })}
        onClick={props.onClick}
        aria-expanded={props.expanded}
        aria-label="view details"
        size={props.size ? props.size: 'medium'}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Tooltip>
  )
}

MoreButton.propTypes = {
  /**
   * A boolean flag that shows if the parent component should be expanded
   */
  expanded: PropTypes.bool.isRequired,
  /**
   * The size of the button. Possible values: 'small', 'medium'
   */
  size: PropTypes.string,
  /**
   * Callback fired when the button is clicked
   */
  onClick: PropTypes.func,
}

export default MoreButton
