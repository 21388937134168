import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Typography } from '@material-ui/core'
import { getBadgeSrc } from '../api/gamification'


/**
 * Renders an alert _toast_ when a **new** badge achieved
 * 
 * @component
 * @category Auxiliary Components
 *
 */
function BadgeToast(props) {

  
  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * URL to the bage image within the front-end app.
   *
   * @name badgeSrc
   * @default ''
   * @prop {object} badgeSrc - state
   * @prop {function} setBadgeSrc - setter
   * @type {state}
   * @memberOf BadgeToastBase
   * @inner
   */
  const [badgeSrc, setBadgeSrc] = React.useState('')


  /**
   * When the component is mounted, calls the back-end
   * [getBadgeSrc]{@link module:Gamification~getBadgeSrc} 
   * to retrieve the badge image then sets the UEL of the image
   * to state [badgeSrc]{@link BadgeToast~badgeSrc}.
   *
   * @name useEffect
   * @function
   * @memberOf BadgeToast
   * @inner
   */
  React.useEffect(() => {
    // get bage src
    getBadgeSrc(props.user, props.uri).then(src => {
      setBadgeSrc(src)
    }).catch(error => {
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <img
          src={badgeSrc}
          height={25}
          alt="New Badge"
        />
      </Grid>

      <Grid item>
        <Typography
          variant="subtitle1"
          component="div"
        >
          {props.text}
        </Typography>
      </Grid>
    </Grid>
  )
}

BadgeToast.propTypes = {
  /**
   * Badge image URI in the back-end
   */
  uri: PropTypes.string.isRequired,
  /**
   * Alert message
   */
  text: PropTypes.string.isRequired,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object.isRequired,
}


// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(BadgeToast)
