import React from 'react'
import PropTypes from 'prop-types'
import { uriLogo } from '../logo'


/**
 * This component renders the app's logo for the top bar
 * 
 * @component
 * @category Auxiliary Components
 * @subcategory Logo
 */
function TopBarLogo(props) {

  /**
   * Method<br/>
   * Derives the URI to the logo image in the back-end.
   * 
   * @returns {string}
   */
  const getSrc = () => {
    if (props.logo)
      return uriLogo + props.logo

    return uriLogo + "default/top"
  }

  return (
    <img
      src={getSrc()}
      alt="topLogo"
      height={props.size}
      width={2*props.size}
    />
  )
}

TopBarLogo.propTypes = {
  /**
   * Filename of the logo in the back-end
   */
  logo: PropTypes.string,
  /**
   * The size of the logo
   */
  size: PropTypes.number,
}

export default TopBarLogo
