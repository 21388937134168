import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { 
  CardContent,
  Grid,
  Typography,
  Collapse,
} from '@material-ui/core'
import { GenericSection } from '../policyDetails'
import { CardActive, CardTop, hideTime } from '../../styles/cards'
import { removePolicy } from '../../redux/actions'
import CardCloseButton from '../../components/closeButton'
import CustomerPolicies from './policies'

//import {BrokeCard} from '../debug/damageCard'


// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },

  heading: {
    marginLeft: theme.spacing(1),
  }

}))


/**
 * It is a component that renders the details of a customer within
 * [customer card]{@link Customer}
 *
 * @prop {object} props
 * a [customer object]{@link Customer.customer}
 * @memberOf Customer
 */
function CustomerDetails(props) {
  const {t} = useTranslation('partner')

  return(
    <GenericSection
      title={t("partner:details")}
      data={{
        [t("partner:birthDate")]: props.birthDate,
        [t("partner:address")]: props.houseNumber + " " + props.street,
        [t("partner:city")]: props.city,
        [t("partner:postcode")]: props.postCode,
        [t("partner:email")]: props.email,
        [t("partner:telefon")]: props.phone,
      }}
    />
  )
}


/**
 * This component renders a customer card within {@link PolicyView}.
 * To show a policy instance as a customer card, its request status should be _customer_.
 * @see [MapPolicyCard]{@link PolicyView.MapPolicyCard} for possible request status of a policy instance
 *
 * @component
 * @category Policy
 * @subcategory Customer
 */
function Customer(props) {
  const classes = useStyles()

  /**
   * Customer instance extracted from _prop_ [customer]{@link Customer}
   *
   * @name customer
   * @type {object}
   * @memberOf Customer
   * @prop {string} partnerNumber
   * ID number of the customer
   * @prop {string} label
   * a string, which briefly displays the customer 
   * @prop {string} firstName
   * customer's first name
   * @prop {string} lastName
   * customer's last name
   * @prop {string} birthDate
   * the date of birth of the customer
   * @prop {string} postCode
   * customer's address: post code
   * @prop {string} city
   * customer's address: city
   * @prop {string} street
   * customer's address: street
   * @prop {string} houseNumber
   * customer's address: house number
   */
  const {index, customer} = props

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that defines the card visibility.
   * Used to animate the card appearance and closure. 
   *
   * @name isVisible
   * @default false
   * @prop {boolean} isVisible - state
   * @prop {function} setIsVisible - setter
   * @type {state}
   * @memberOf Customer
   * @inner
   */
  const [isVisible, setIsVisible] = React.useState(false)

  /**
   * Implements animation of the card appearance
   * by setting _true_ to state [isVisible]{@link Customer~visible}.
   *
   * @name useEffect
   * @function
   * @memberOf Customer
   * @inner
   */
  React.useEffect(() => {
    setIsVisible(true)
  }, [])

  //console.log('CUSTOMER:')
  //console.log(props)

  return (
    <Collapse
      in={isVisible}
      timeout={hideTime}
      unmountOnExit
    >
      <CardActive>
        <CardTop
          className={classes.card}
          action={
            <React.Fragment>

              {/* DEBUG: broke antrag 
              <BrokeCard card="Customer" />
            */}

              {/* Close Button */}
              <CardCloseButton
                onClose={() => setIsVisible(false)}
                onDelete={() => props.closeCard(index)}
              />

            </React.Fragment>
          }
          title={
            <Typography
              className={classes.heading}
              component="p"
              variant="h5"
            >
              {customer.firstName + " " + customer.lastName}
            </Typography>
          }
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12}>
              <CustomerDetails {...customer} />
            </Grid>
            <Grid item xs={12}>
              <CustomerPolicies customer={customer.partnerNumber} />
            </Grid>
          </Grid>
        </CardContent>
      </CardActive>
    </Collapse>
  )
}

Customer.propTypes = {
  /**
   * The index of the customer instance in the [redux store]{@link module:State~policies}
   */
  index: PropTypes.number,
  /**
   * The customer instance (see [customer]{@link Customer.customer})
   */
  customer: PropTypes.object,
  /**
   * Redux action [removePolicy]{@link module:Actions.removePolicy}
   */
  closeCard: PropTypes.func,
}


const mapDispatchToProps = {
  closeCard: removePolicy,
}

export default connect(null, mapDispatchToProps)(Customer)
