import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { 
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getCompanyLogo, TopBarLogo } from '../components/logo'
import UserMenu from '../components/userMenu'


// styles
const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: "2px solid",
    borderBottomColor: "#aaa",
    marginBottom: theme.spacing(1),
  },

  leftContainer: {
    display: "flex",
    width: "340px",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  rightContainer: {
    display: "flex",
    width: "340px",
    alignItems: "center",
    justifyContent: "flex-end",
  },

  centerContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },

  user: {
    color: "grey",
  },

  toolbarItem: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}))


/**
 * This component defines the layout of the top bar of **PoLZy** application.
 * 
 * @component
 * @category Auxiliary Components
 */
function Header(props){
  const classes = useStyles()
  
  return(
    <React.Fragment>
      <Toolbar className={classes.toolbar}>

        {/* Left Part */}
        <div className={classes.leftContainer}>
          <Typography
            classes={{root: classes.company}}
            variant="h5"
            color="primary"
          >
            {props.user.company.displayedName}
          </Typography>
        </div>

        {/* Central Part */}
        <div className={classes.centerContainer}>
          <TopBarLogo
            logo={getCompanyLogo(props.user.company.attributes, "top")}
            size={100}
          />
        </div>

        {/* Right Part */}
        <div className={classes.rightContainer}>
          <div className={classes.toolbarItem}>
            <UserMenu {...props} />
          </div>
        </div>
      </Toolbar>
    </React.Fragment>
  )
}

Header.propTypes = {
  /**
   * The [flag of the current view]{@link ViewFlags}. 
   */
  currentView: PropTypes.string,
  /**
   * A boolean flag which shows if the data on gamification badges
   * should be loaded from the back-end 
   */
  updateBadges: PropTypes.bool,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Callback fired to change the current view
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when the badge types are retrieved from the back-end
   */
  onBadgesUpdated: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(Header)
