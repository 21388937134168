import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
} from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import BusinessIcon from '@material-ui/icons/Business'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import PartnerField from './partnerField'
import { updateAddressList } from '../../redux/actions'


// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: 0,
  },

  tabs: {
    marginBottom: theme.spacing(2),
  },
}))

/**
 * **PoLZy** operates _two_ types of partner,
 * described in the table below. 
 *
 * | Name            | Type     | Value     |
 * | --------------- | -------- | --------- |
 * | PARTNER_PERSON  | `string` | "person"  |
 * | PARTNER_COMPANY | `string` | "company" |
 *
 * @category Data Fields
 * @subcategory Search Field
 * @name PartnerTypes
 */
const PARTNER_PERSON = 'person'
const PARTNER_COMPANY = 'company'

/**
 * Renders a dialog as a modal window to create a new partner instance on the back-end.
 *
 * @component
 * @category Data Fields
 * @subcategory Search Field
 */
function NewDialog(props) {
  const classes = useStyles()
  const {t} = useTranslation("common", "antrag", "partner")

  /**
   * Defines fields and its initial values of the create partner form.
   *
   * @type {object}
   * @prop {object} person
   * The fields which related to a person partner
   * @prop {object} company
   * The fields which related to a company partner
   */
  const initPartner = {
    [PARTNER_PERSON]: {
      firstName: '',
      lastName: '',
      birthDate: '2000-01-01',
      gender: '',
      address: '',
      email: '',
      telefon: '',
    },
    [PARTNER_COMPANY]: {
      companyName: '',
      registrationNumber: '',
      companyType: '',
      address: '',
      email: '',
      telefon: '',
    },
  }

  
  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * Current values of a new partner's fields.
   *
   * @name partner
   * @default [initPartner.person]{@link NewDialog.initPartner}
   * @prop {object} partner - state
   * @prop {function} setPartner - setter
   * @type {state}
   * @memberOf NewDialog
   * @inner
   */
  const [partner, setPartner] = React.useState({...initPartner[PARTNER_PERSON]})

  /**
   * State<br/>
   * Current partner type.<br/>
   * Possible values: see [Partner Types]{@link PartnerTypes}
   *
   * @name partner
   * @default [PARTNER_PERSON]{@link PartnerTypes}
   * @prop {string} partnerType - state
   * @prop {function} setPartnerType - setter
   * @type {state}
   * @memberOf NewDialog
   * @inner
   */
  const [partnerType, setPartnerType] = React.useState(PARTNER_PERSON)

  /**
   * Event Handler<br/>
   * **_Event:_** change tab<br/>
   * **_Implementation:_**
   * update state [partnerType]{@link NewDialog~partnerType} by the selected partner type and
   * set corresponded [initial values]{@link NewDialog.initPartner}
   * to state [partner]{@link NewDialog.partner}.
   */
  const handleTabChange = (event, value) => {
    setPartner({...initPartner[value]})
    setPartnerType(value)
  }

  /**
   * Callback fired when the value of an input field of the dialog should be changed. 
   */
  const handleDataChange = (newValues) => {
    setPartner(preValues => ({
      ...preValues,
      ...newValues,
    }))
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click _Create_ button<br/>
   * **_Implementation:_**
   * includes the following steps:
   * 1. calls prop [updateAddress]{@link NewDialog} to update address associated with the current data field
   * 2. derives _label_ (string representation) for the newly created partner
   * 3. calls prop [onChange]{@link NewDialog} to update value of the data field
   * 4. calls prop [onClose]{@link NewDialog} to close the dialog
   */
  const handleCreateClick = () => {
    // save address
    props.updateAddress(
      props.id,
      {
        [props.data.name]: partner.address,
      },
    )

    // add partner label
    const partnerLabelKeys = {
      [PARTNER_PERSON]: [
        'lastName',
        'firstName',
        'birthDate',
        //'address',
      ],
      [PARTNER_COMPANY]: [
        'companyName',
        'companyType',
        'registrationNumber',
        //'address',
      ],
    }

    const partnerLabel = partnerLabelKeys[partnerType].reduce((label, key) => ([
      ...label,
      //typeof(partner[key]) === 'object' && partner[key].label ? partner[key].label : partner[key]
      partner[key],
    ]), []).join(' ') + ' ' + partner.address.label
 
    // add partner value
    props.onChange({
      [props.data.name]: {
        ...partner,
        label: partnerLabel,
      }
    })

    // close dialog
    props.onClose()
  }

  /**
   * Method<br/>
   * Validates if values set to all the fields of the dialog.
   */
  const validateForm = () => {
    for (const field in partner) {
      if (partner[field] === '') {
        return false
      }
    }
    return true
  }

  return (
    <Dialog 
      open={props.open}
      onClose={props.onClose}
      aria-labelledby={`new-partner-title-${props.id}`}
    >
      <DialogTitle
        classes={{root: classes.title}}
        id={`new-partner-title-${props.id}`}
      >
        {t("antrag:partner.new")}
      </DialogTitle>
      <DialogContent>
        <Tabs 
          classes={{root: classes.tabs}}
          value={partnerType}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={t('partnet:person')}
            icon={<PersonIcon />}
            value={PARTNER_PERSON}
            id="create-tab-person"
            aria-controls="tabpanel-person"
          />
          <Tab
            label={t('partner:company')}
            icon={<BusinessIcon />}
            value={PARTNER_COMPANY}
            id="create-tab-company"
            aria-controls="tabpanel-company"
          />
        </Tabs>
        <Grid container spacing={2}>
          {Object.keys(partner).map(key => (
            <Grid item xs={12} key={key}>
              <PartnerField
                {...props}
                value={partner[key]}
                onChange={handleDataChange}
                data={{
                  name: key,
                  brief: t(`partner:${key}`),
                  isMandatory: true
                }}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button
          color="primary"
          onClick={handleCreateClick}
          disabled={!validateForm()}
        >
          {t("common:create")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewDialog.propTypes = {
  /**
   * ID of the parent instance
   */
  id: PropTypes.string,
  /**
   * Data of the related search field
   */
  data: PropTypes.object,
  /**
   * A boolean flag that signals if the dialog is open
   */
  open: PropTypes.bool.isRequired,
  /**
   * Callback fired when the dialog should be closed
   */
  onClose: PropTypes.func.isRequired,
  /**
   * Callback fired to update the value of the related data field
   * with the created partner
   */
  onChange: PropTypes.func,
  /**
   * _Redux_ action [updateAddressList]{@link module:Actions.updateAddressList}
   */
  updateAddress: PropTypes.func,
}


// connect to redux store
const mapDispatchToProps = {
  updateAddress: updateAddressList,
}

export default connect(null, mapDispatchToProps)(NewDialog)
