import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew'
import { useTranslation } from 'react-i18next'
import DataFieldText from '../textField'
import FindDialog from './findDialog'
import NewDialog from './newDialog'
import SearchDropDown from './searchDropDown'

/**
 * Renders data field of type _SearchEndPoint_.
 *
 * @component
 * @category Data Fields
 * @subcategory Search Field
 */
function SearchField(props) {
  const {t} = useTranslation('common')

  /**
   * Field data extracted from _prop_ [data]{@link SearchField}.
   * The properties of the object are the same as for
   * {@link DataFieldText.data} plus extra ones listed in the table.
   *
   * @name data
   * @type {object}
   * @memberOf SearchField
   * @prop {string} name
   * the name of the data field
   * @prop {string} tooltip
   * a tooltip of the data field
   * @prop {string} endpoint
   * associated search endpoint on the back-end
   */
  const {data, value } = props

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that shows if a [find partner dialog]{@link FindDialog} should be open.
   *
   * @name findOpen
   * @default false
   * @prop {bool} findOpen - state
   * @prop {function} setFindOpen - setter
   * @type {state}
   * @memberOf SearchField
   * @inner
   */
  const [findOpen, setFindOpen] = React.useState(false)

  /**
   * State<br/>
   * A boolean flag that shows if a [new partner dialog]{@link NewDialog} should be open.
   *
   * @name newOpen
   * @default false
   * @prop {bool} newOpen - state
   * @prop {function} setNewOpen - setter
   * @type {state}
   * @memberOf SearchField
   * @inner
   */
  const [newOpen, setNewOpen] = React.useState(false)

  /**
   * Callback fired when [find partner dialog]{@link FindDialog} should be opened.
   */
  const handleFindOpen = () => {
    setFindOpen(true)
  }

  /**
   * Callback fired when [find partner dialog]{@link FindDialog} should be closed.
   */
  const handleFindClose = () => {
    setFindOpen(false)
  }

  /**
   * Callback fired when [new partner dialog]{@link NewDialog} should be opened.
   */
  const handleNewOpen = () => {
    setNewOpen(true)
  }

  /**
   * Callback fired when [new partner dialog]{@link NewDialog} should be closed.
   */
  const handleNewClose = () => {
    setNewOpen(false)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click clear button<br/>
   * **_Implementation:_**
   * calls prop [onChange]{@link SearchField} to set empty string to value of the field.
   */
  const handleClearClick = () => {
    props.onChange({[data.name]: ''})
  }


  return (
    <React.Fragment>
      {data.endpoint === "partner" ? (
        <React.Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={10}>
              <DataFieldText
                data={data}
                value={value ? value.label : ""}
                onClick={handleFindOpen}
                endAdornment={Boolean(value) &&
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClearClick}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
              />
            </Grid>
            <Grid item xs={6} lg={1}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleFindOpen}
                startIcon={<SearchIcon fontSize="small" />}
              >
                {t('common:find')}
              </Button>
            </Grid>
            <Grid item xs={6} lg={1}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleNewOpen}
                startIcon={<AccessibilityNewIcon />}
              >
                {t('common:new')}
              </Button>
            </Grid>
          </Grid>
          <FindDialog 
            {...props}
            open={findOpen}
            onClose={handleFindClose}
          />
          <NewDialog 
            {...props}
            open={newOpen}
            onClose={handleNewClose}
          />
        </React.Fragment>
      ) : (
        <Tooltip
          title={data.tooltip}
          placement="top"
        >
          <div>
            <SearchDropDown {...props} />
          </div>
        </Tooltip>
      )}
    </React.Fragment>
  )
}

SearchField.propTypes = {
  /**
   * Field data (see [data]{@link DataFieldNumberRange.data})
   */
  data: PropTypes.object.isRequired,
  /**
   * Value of the numeric field
   */
  value: PropTypes.any,
  /**
   * Callback fired to change the value of the data field
   */
  onChange: PropTypes.func,
}

export default SearchField