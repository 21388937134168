import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputAdornment,
  IconButton,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import SearchDropDown from './searchDropDown'
import DataFieldDate from '../dateField'
import DataFieldText from '../textField'
import DataFieldSelect from '../selectField'


// Styles
const useStyles = makeStyles((theme) => ({
  flexContainerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
}))


/**
 * A radio group component used to render input field with several options
 * (like _gender_ field). 
 *
 * @memberOf PartnerField
 */
function InputRadio(props) {
  const classes = useStyles()

  return (
    <FormControl
      component="fieldset"
      required={props.data.isMandatory}
    >
      <FormLabel component="legend">{props.data.brief}</FormLabel>
      <RadioGroup
        classes={{root: classes.flexContainerRow}}
        aria-label="gender"
        name={props.data.name}
        value={props.value}
        onChange={(e) => props.onChange({[props.data.name]: e.target.value})}
      >
        {props.options.map((opt, index) => (
          <FormControlLabel
            key={index}
            value={opt.value}
            control={<Radio />}
            label={opt.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

/**
 * Renders an input field within [new partner dialog]{@link NewDialog}
 *
 * @component
 * @category Data Fields
 * @subcategory Search Field
 * @returns either of:
 * <br/>{@link DataFieldText}
 * <br/>{@link DataFieldDate}
 * <br/>{@link DataFieldSelect}
 * <br/>{@link SearchDropDown}
 * <br/>[InputRadio]{@link PartnerField.InputRadio}
 */
function PartnerField(props) {
  const {t} = useTranslation("partner")

  /**
   * List of the possible values for _gender_ field.
   *
   * @type object[]
   * @prop {string} value
   * value of the current gender option
   * @prop {string} label
   * label of the current gender option
   */
  const genderOptions = [
    {
      value: 'm',
      label: t("partner:gender.male"),
    },
    {
      value: 'f',
      label: t("partner:gender.female"),
    },
    {
      value: '3',
      label: t("partner:gender.float")
    },
  ]


  /**
   * Event Handler<br/>
   * **_Event:_** click _Clear_ button<br/>
   * **_Implementation:_** sets empty string to the field value
   * 
   */
  const handleClearClick = () => {
    props.onChange({[props.data.name]: ""})
  }

  switch (props.data.name) {
    case 'birthDate':
      return <DataFieldDate {...props} />

    case 'gender':
      return <InputRadio {...props} options={genderOptions} />

    case 'address':
      //console.log("Partner's Address Field:")
      //console.log(props)
      
      if (props.value) {
        return (
          <DataFieldText
            {...props}
            value={props.value.label}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClearClick}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
        )
      }

      return (
        <SearchDropDown
          {...props}
          data={{
            ...props.data,
            endpoint: "address"
          }}
          disableSave
          onChange={props.onChange}
        />
      )

    case 'companyType':
      // using value list "firmenArten"
      return (
        <DataFieldSelect 
          {...props}
          data={{
            ...props.companyTypes,
            name: 'companyType',
            brief: (t('partner:companyType')),
            inputRange: ["async", "firmenArten"],
          }}
        />
      )

    default:
      return <DataFieldText {...props} />
  }
}

PartnerField.propTypes = {
  /**
   * The data of the input field that defines:
   * _name_, _brief_ and _isMandatory_ flag
   */
  data: PropTypes.object,
  /**
   * The current value of the input field
   */
  value: PropTypes.any,
  /**
   * Callback that fired to update the valu eof the field
   */
  onChange: PropTypes.func,
}

export default PartnerField