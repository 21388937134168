import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Badge,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import HomeIcon from '@material-ui/icons/Home'
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import LanguageSelector from './languageSelector'
import FileUploadDialog from './fileUploads'
import { getBadges } from '../api/gamification'
import { VIEW_HOME, VIEW_ADMIN, VIEW_BADGE, VIEW_RANKING } from '../views/MainView'
import { signOut, updateUser, clearPolicy, clearAntrag, clearValues } from '../redux/actions'
import { ErrorBoundary } from "@sentry/react"
import { getManualDialogOptions, getManualReportContext, getUser } from '../sentry/utils'


const useStyles = makeStyles({
  list: {
    width: 250,
  },

  languageSelector: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  }
})


/**
 * This component renders a _report button_ within the user's menu.
 * To be consistent with Sentry, the component throws an error to
 * activate Sentry feed-backform.
 * 
 * @prop {object} props
 * @prop {string} caption
 * Caption of the _report button_
 * @prop {function} onClick
 * Calback fired when the button is clicked.
 * It is used to close the user menu before throwing an error.
 * 
 * @memberOf UserMenu
 */
function ReportButton(props) {
  const [report, setReport] = React.useState(false)

  const handleClick = () => {
    props.onClick()
    setReport(true)
  }

  React.useEffect(() => {
    if (report) {
      setReport(false)
    }
  }, [report])

  if (report) {
    throw new Error("Report Problem")
  }

  return (
    <ListItem
      button
      onClick={handleClick}
    >
      <ListItemIcon>
        <ReportProblemIcon />
      </ListItemIcon>
      <ListItemText primary={props.caption} />
    </ListItem>
  )
}


/**
 * This component renders a button which toggles user's menu.
 * The menu is displayed as a side sheet. 
 *
 * @component
 * @category Auxiliary Components
 */
function UserMenu(props) {
  const {t} = useTranslation('auth', 'admin', 'common', 'feedback', 'gamification')
  const classes = useStyles()

  /**
   * @typedef {object} state
   * @ignore
   */
  /**
   * State<br/>
   * A boolean flag that shows is the menu is opened now
   *
   * @name openMenu
   * @default false
   * @prop {bool} openMenu - state
   * @prop {function} setOpenMenu - setter
   * @type {state}
   * @memberOf UserMenu
   * @inner
   */
  const [openMenu, setOpenMenu] = React.useState(false)

  /**
   * State<br/>
   * A boolean flag that shows is the [file upload dialog]{@link FileUploadDialog} is opened now
   *
   * @name openUpload
   * @default false
   * @prop {bool} openUpload - state
   * @prop {function} setOpenUpload - setter
   * @type {state}
   * @memberOf UserMenu
   * @inner
   */
  const [openUpload, setOpenUpload] = React.useState(false)

  /**
   * When _prop_ [updateBadges]{@link UserMenu} is _true_,
   * calls the back-end [getBadges]{@link module:Gamification.getBadges}
   * to update data of user's gamification badges.
   * Then pushes the retrieved data to _prop_ [setBadges]{@link UserMenu} .
   *
   * @name useEffect
   * @function
   * @memberOf UserMenu
   * @inner
   * @arg {bool} updateBadges
   * _prop_ [updateBadges]{@link UserMenu}
   */
  React.useEffect(() => {
    if (!props.updateBadges) {
      return
    }

    getBadges(props.user).then((data) => {
      // set user badges
      props.setBadges(data)
      // disable bdges update
      props.onBadgesUpdated()
    }).catch(error => {
      // disable badges update
      props.onBadgesUpdated()
      console.log(error)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateBadges])


  /**
   * Method<br/>
   * Derives the number of user's badges which were not seen before.
   * 
   * @returns {number}
   */
  const getUnseenBadgeNumber = () => {
    if (!(props.user.badges instanceof Array)) {
      return 0
    }

    const unseenBadges = props.user.badges.filter(badge => !badge.isSeen)

    return unseenBadges.length
  }


  /**
   * Event Handler<br/>
   * **_Event:_** click a view button.<br/>
   * **_Implementation:_**<br/>
   * Pushes the [flag]{@link ViewFlag} of the selected view to 
   * _prop_ [onChange]{@link UserMenu} then closes the menu
   */
  const handleShowView = (view) => {
    props.onChange(view)
    setOpenMenu(false)
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click _sign-out_ button.<br/>
   * **_Implementation:_**<br/>
   * Removes the currently loaded user data from the app memory by calling _props_:
   * [clearPolicy]{@link UserMenu},
   * [clearAntrag]{@link UserMenu},
   * [clearValues]{@link UserMenu},
   * [signOut]{@link UserMenu}
   */
  const handleSignOut = () => {
    // clear policy and antrag store
    props.clearPolicy()
    props.clearAntrag()
    // clear lists of values
    props.clearValues()
    // sign out 
    props.signOut()
  }

  /**
   * Event Handler<br/>
   * **_Event:_** click _upload_ button.<br/>
   * **_Implementation:_**<br/>
   * Sets _true_ to state [openUpload]{@link UserMenue~openUpload}
   */
  const handleOpenUploadDialog = () => {
    setOpenMenu(false)
    setOpenUpload(true)
  }

  return (
    <React.Fragment>

    {/* Toggle Button */}
      <Badge
        color="secondary"
        badgeContent={getUnseenBadgeNumber()}
        invisible={getUnseenBadgeNumber() === 0}
        overlap="circle"
      >
        <Button
          onClick={() => setOpenMenu(true)}
        >
          {props.user.name}
        </Button>
      </Badge>

    {/* User Menu */}
      <Drawer 
        anchor="right"
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      >
        <List classes={{root: classes.list}}>

          {/* Language Selector */}
          <ListItem classes={{root: classes.languageSelector}}>
            <LanguageSelector
              noBorders
              withLabel
            />
          </ListItem>
          
          <Divider />

          {/* Problem Reporting */}
          <ErrorBoundary
            showDialog
            dialogOptions={getManualDialogOptions(props.user, t)}
            beforeCapture={(scope) => {
              scope.setUser(getUser(props.user))
              scope.setTag("view", "user menu")
              scope.setContext("user report", getManualReportContext(props.user, "user menu"))
            }}
          >
            <ReportButton
              caption={t('feedback:report.problem')}
              onClick={()=>setOpenMenu(false)}
            />
          </ErrorBoundary>

          {/* File Upload */}
          <ListItem
            button
            onClick={handleOpenUploadDialog}
          >
            <ListItemIcon>
              <CloudUploadIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:upload')} />
          </ListItem>

          {/* Go To Home */}
          {props.currentView !== VIEW_HOME &&
            <ListItem
              button
              onClick={() => handleShowView(VIEW_HOME)}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={t('admin:quit')} />
            </ListItem>
          }

          {/* Admin Pannel */}
          {props.currentView !== VIEW_ADMIN && props.user.isSupervisor &&
            <ListItem
              button
              onClick={() => handleShowView(VIEW_ADMIN)}
            >
              <ListItemIcon>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary={t('admin:pannel')} />
            </ListItem>
          }

          {/* Badges */}
          {props.currentView !== VIEW_BADGE &&
            <ListItem
              button
              onClick={() => handleShowView(VIEW_BADGE)}
            >
              <ListItemIcon>
                <Badge
                  color="secondary"
                  badgeContent={getUnseenBadgeNumber()}
                  invisible={getUnseenBadgeNumber() === 0}
                >
                  <LoyaltyIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t('gamification:badges')} />
            </ListItem>
          }

          {/* Ranking */}
          {props.user.company.attributes && props.user.company.attributes.hitList && props.currentView !== VIEW_RANKING &&
            <ListItem
              button
              onClick={() => handleShowView(VIEW_RANKING)}
            >
              <ListItemIcon>
                <TrendingUpIcon />
              </ListItemIcon>
              <ListItemText primary={t('gamification:ranking')} />
            </ListItem>
          }

          {/* Sign Out */}
          <ListItem
            button
            onClick={handleSignOut}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('auth:signout.button')} />
          </ListItem>
        </List>
      </Drawer>

      {/* File Upload Dialog */}
      <FileUploadDialog
        open={openUpload}
        onClose={() => setOpenUpload(false)}
      />
    </React.Fragment>
  )
}

UserMenu.propTypes = {
  /**
   * The [flag of the current view]{@link ViewFlags}. 
   */
  currentView: PropTypes.string,
  /**
   * A boolean flag which shows if the data on gamification badges
   * should be loaded from the back-end 
   */
  updateBadges: PropTypes.bool,
  /**
   * Redux state [user]{@link module:State~user}
   */
  user: PropTypes.object,
  /**
   * Callback fired to change the current view
   */
  onChange: PropTypes.func,
  /**
   * Callback fired when the badge types are retrieved from the back-end
   */
  onBadgesUpdated: PropTypes.func,
  /**
   * Redux action [signOut]{@link module:Actions.signOut}
   */
  signOut: PropTypes.func,
  /**
   * Redux action [setBadges]{@link module:Actions.setBadges}
   */
  setBadges: PropTypes.func,
  /**
   * Redux action [clearPolicy]{@link module:Actions.clearPolicy}
   */
  clearPolicy: PropTypes.func,
  /**
   * Redux action [clearAntrag]{@link module:Actions.clearAntrag}
   */
  clearAntrag: PropTypes.func,
  /**
   * Redux action [clearValues]{@link module:Actions.clearValues}
   */
   clearValues: PropTypes.func,
}

// connect to redux store
const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  signOut: signOut,
  setBadges: updateUser,
  clearPolicy: clearPolicy,
  clearAntrag: clearAntrag,
  clearValues: clearValues, 
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)