import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import ReactSpeedometer from 'react-d3-speedometer'

/*
** speedometer size
*/
export const speedometerSize = 250

// set styles
const useStyles = makeStyles((theme) => ({
  container: {
    width: speedometerSize,
    height: Math.floor(speedometerSize/2),
  },
}))


/**
 * This component renders a speedometer-like gauge using
 * [react-d3-speedometer]{@link https://www.npmjs.com/package/react-d3-speedometer}.
 * The measurement scale of the speedometer is in range [0, 1000]
 * 
 * @component
 * @category Auxiliary Components
 */
function Speedometer(props) {
  const classes = useStyles()
  const {t} = useTranslation('speedometer')
  
  /**
   * Size of the color segments on the speedometer scale<br/>
   * Example: [0, 333, 667, 1000]
   * * 0-333     --> segment 1
   * * 333-667   --> segment 2
   * * 667- 1000 --> segment 3
   * 
   * @type {number[]}
   */
  const segmentStops = [0, 333, 667, 1000]

  /**
   * The colors of the scale segments<br/>
   * Example: ['#ffee58', '#d4e157', '#9ccc65']
   * 
   * @type {string[]}
   */
  const segmentColors = ['#ffee58', '#d4e157', '#9ccc65']

  /**
   * The labels of the scale segments
   * 
   * @type {object[]}
   * @prop {string} text - the text of the label
   * @prop {string} fontSize - the font size of the label
   * @prop {string} color - the color of the text
   * @prop {string} position - label position either "INSIDE" or "OUTSIDE"
   */
  const segmentLabels = [
    {
      text: t('fair'),
      position: 'INSIDE',
      color: '#555',
    },
    {
      text: t('good'),
      position: 'INSIDE',
      color: '#555',
    },
    {
      text: t('great'),
      position: 'INSIDE',
      color: '#555',
    },
  ]

  /**
   * Properties of the speedometer needle
   * 
   * @type {object}
   * @prop {number} height - the height of the needle: float between 0.0 and 1.0
   * @prop {string} color - the color of the needle
   * @prop {number} transitionDuration
   * time in milliseconds that takes the needle to change its position
   */
  const needle = {
    height: 0.7,
    color: '#42a5f5',
    transitionDuration: 3000,
  } 

  return(
    <div className={classes.container}>
      <ReactSpeedometer
        fluidWidth
        value={props.value}
        customSegmentStops={segmentStops}
        segmentColors={segmentColors}
        currentValueText=""
        customSegmentLabels={segmentLabels}
        needleHeightRatio={needle.height}
        needleTransitionDuration={needle.transitionDuration}
        needleTransition="easeElastic"
        needleColor={needle.color}
      />
    </div>
  )
}

Speedometer.propTypes = {
  /**
   * The value to be shown be the speedometer
   */
  value: PropTypes.number.isRequired,
}

export default Speedometer
